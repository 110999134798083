import React, { useEffect, useState } from 'react';
import { Button, Segmented, Tag } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import _ from 'lodash';

import { FilterProps, FilterType, SourceFilterValue } from 'components/SequenceTable/types';

import stylesheet from './SourceFilter.module.scss';
import { DataSourceDictionary, dataSources } from 'common/DataSources';
import { SequenceData } from 'types';
import { SEQUENCES_LIMIT } from 'api';

export enum Condition {
  MUST_BE = 'MUST_BE',
  MAY_BE = 'MAY_BE',
  MUST_NOT_BE = 'MAST_NOT_BE',
}

export const CONDITION_OPTIONS = [{
  label: 'Must be',
  value: Condition.MUST_BE,
}, {
  label: 'May be',
  value: Condition.MAY_BE,
}, {
  label: 'Must not be',
  value: Condition.MUST_NOT_BE,
}];

/**
 * Returns only the data sources that are used in SequenceData.
 */
export function filteredDataSources(sequenceData: SequenceData | undefined) {
  if (!sequenceData) {
    return dataSources;
  }

  if (sequenceData.length >= parseInt(SEQUENCES_LIMIT) || parseInt(SEQUENCES_LIMIT) === 0) {
    // We can not locally filter if we have not fetched all sequence data
    return dataSources;
  }

  const filteredDataSource: DataSourceDictionary = {};

  for (let i = 0; i < sequenceData.length; i++) {
    if (Object.keys(filteredDataSource).length === Object.keys(dataSources).length) {
      break;
    }
    for (let j = 0; j < sequenceData[i].source.length; j++) {
      if (!filteredDataSource[sequenceData[i].source[j]]) {
        filteredDataSource[sequenceData[i].source[j]] = dataSources[sequenceData[i].source[j]];

        if (Object.keys(filteredDataSource).length === Object.keys(dataSources).length) {
          break;
        }
      }
    }
  }

  return filteredDataSource;
}

type Props = FilterProps<SourceFilterValue> & FilterDropdownProps;

export function SourceFilter(props: Props): JSX.Element {
  const [dataSources, setDataSources] = useState<DataSourceDictionary>();
  const [value, setValue] = useState<SourceFilterValue>();

  const handleChange = (label: string, condition: Condition) => {
    setValue({ ...value, [label]: condition });
  };

  const handleApply = () => {
    if (!_.isEmpty(value)) {
      props.onFilter(props.column, { type: FilterType.SOURCE, value });
    } else {
      props.onFilter(props.column, undefined);
    }

    props.confirm({ closeDropdown: true });
  };

  const handleClear = () => {
    setValue(undefined);
    props.onFilter(props.column, undefined);
  };

  const handleReset = () => {
    setValue(undefined);
    props.onFilterReset(props.column);
    props.confirm();
  };

  useEffect(() => {
    if (props.visible) {
      setValue(props.value);
    }
  }, [props.visible]);

  useEffect(() => {
    setDataSources(filteredDataSources(props.allData));
  }, [props.allData]);

  const renderFilters = () => {
    return _.map(dataSources, (label: { title: string, short_title: string }) => {
      return (
        <div key={label.short_title} className={stylesheet.item}>
          <Tag className={stylesheet.label}>{label.title}</Tag>
          <Segmented
            value={value?.[label.title] ?? Condition.MAY_BE}
            options={CONDITION_OPTIONS}
            onChange={(value) => handleChange(label.title, value as Condition)}
          />
        </div>
      );
    });
  };

  return (
    <div className={stylesheet.root}>
      <div className={stylesheet.container}>
        {renderFilters()}
      </div>

      <div className={stylesheet.footer}>
        <Button
          size="small"
          className={stylesheet.button}
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          size="small"
          className={stylesheet.button}
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button
          size="small"
          type="primary"
          className={stylesheet.button}
          onClick={handleApply}
        >
          Apply
        </Button>
      </div>
    </div>
  );
}

export function getSourceFilter(props: FilterProps<SourceFilterValue>) {
  return function SourceFilterWrapper(dropdownProps: FilterDropdownProps): JSX.Element {
    return (<SourceFilter {...props} {...dropdownProps} />);
  };
}
