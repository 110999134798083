import { useEffect, useState } from 'react';

import { Tabs, TabsProps } from 'antd';
import _ from 'lodash';
// Components
import { ProfileTab, PapersTab, SourcesTab, StructureTab, TagTab, LocationTab, DockingTab } from './Tabs';
import { OptimisationTab } from './Tabs/OptimisationTab/OptimisationTab';
// API
import { SEQUENCES_LIMIT, sequenceApi } from 'api';
// Types
import { Config, Paper, SequenceColumn, SequenceSource, Structure, Location, Tag } from 'types';
// Styles
import stylesheet from './SequenceInfo.module.scss';

type Props = {
  sequence?: SequenceColumn | undefined;
  projectId: string;
  groupId: number;
  handleNameEdition: (value: string, seq_id: string) => void;
  frontendConfig: Config['frontendConfig'];
  selectedRow: string;
};

export function SequenceInfo({
  sequence = undefined,
  projectId,
  groupId,
  handleNameEdition,
  frontendConfig,
  selectedRow
}: Props) {
  const [sequenceMetaData, setSequenceMetaData] = useState<SequenceColumn | undefined>(sequence);
  const [papers, setPapers] = useState<Paper[]>([]);
  const [sources, setSources] = useState<SequenceSource[]>([]);
  const [structures, setStructures] = useState<Structure[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);

  const [loading, setLoading] = useState(false);

  const getName = (name: string, length: number) => <div className={stylesheet.label}>{name}{!loading && <div className={stylesheet.labelNumber}>{length}</div>}</div>;

  const items: TabsProps['items'] = [
  {
    key: 'profile',
    label: 'Profile',
    disabled: _.isEmpty(sequenceMetaData),
    children:
      sequenceMetaData && <ProfileTab
        groupId={groupId}
        projectId={projectId}
        sequence={(sequenceMetaData)}
        readOnly={frontendConfig?.readOnly ?? false}
        handleNameEdition={handleNameEdition}
      />,
  },
 {
    key: 'papers',
    label: getName('Publications', papers.length),
    children: <PapersTab papers={papers} loading={loading} />,
  }, {
    key: 'sources',
    label: getName('Sources', sources.length),
    children: <SourcesTab sources={sources} loading={loading} />,
  }
];
  if (sequenceMetaData?.id) {
    items?.push({
      key: 'structure',
      label: getName('Structure', structures.length),
      children: <StructureTab sequenceId={sequenceMetaData?.id} structures={structures} loading={loading} />
    });
  }
  if (!frontendConfig?.nagoya.enable && sequenceMetaData?.id) {
    items?.push({
      key: 'location',
      label: getName('Nagoya', locations.length),
      children: <LocationTab locations={locations} loading={loading} />
    });
  }
  if (frontendConfig?.tags.enable && sequenceMetaData) {
    items?.push({
      key: 'tags',
      label: getName('Tags', Object.keys(sequenceMetaData?.tags)?.length),
      children: <TagTab
        projectId={projectId}
        sequenceId={sequenceMetaData?.id}
        sequence={(sequenceMetaData)}
        tags={tags}
        loading={loading} />
    });
  }
  if (frontendConfig?.ligandDocking.enable && sequenceMetaData) {
    items?.push({
      key: 'docking',
      label: getName('Docking', _.keys(sequenceMetaData?.ligand_docking)?.length),
      children: <DockingTab
        projectId={projectId}
        sequence={(sequenceMetaData)}
        structures={structures}
        loading={loading} />
    });
  }

  if (frontendConfig?.optimisation.enable && sequenceMetaData?.id) {
    items?.push({
      key: 'optimisation',
      label: 'Optimisation',
      children: <OptimisationTab sequenceId={sequenceMetaData?.id} structures={structures} loading={loading} projectId={projectId} />
    });
  }

  const fetchSequences = (sequenceId: string) => {
    setLoading(true);
    setPapers([]);
    setSources([]);
    setStructures([]);
    setLocations([]);
    setTags([]);

    Promise.all([
      sequenceApi.getSequenceMetadata({ projectId, sequenceId, type: 'papers' }),
      sequenceApi.getSequenceMetadata({ projectId, sequenceId, type: 'sources' }),
      sequenceApi.getSequenceMetadata({ projectId, sequenceId, type: 'structures' }),
      !frontendConfig?.nagoya.enable && sequenceApi.fetchSeqLocations({ projectId, sequenceId }),
      frontendConfig?.tags.enable && sequenceApi.getProjectTags({ projectId })
    ]).then(([papers, sources, structures, location, tags]) => {
      setPapers(papers);
      setSources(sources);
      setStructures(structures);
      location && setLocations(location);
      tags && setTags(tags);

      setLoading(false);
    }).catch(error => console.error('Get seq data:', error));

    projectId && groupId && sequenceApi.getSequenceData({ projectId, groupId, filters: { id: sequenceId }, limit: SEQUENCES_LIMIT })
      .then((seqData) => {
        // Fetch specific sequence by sequence id in order to get tags object
        seqData[0] && setSequenceMetaData(state => {
          return { ...state, ...seqData[0] };
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (selectedRow) {
      fetchSequences(selectedRow);
    }
  }, [selectedRow]);

  return (
    <Tabs
      className={stylesheet.root}
      defaultActiveKey="profile"
      items={items}
    />
  );
}
