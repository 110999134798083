import { axios } from './index';
import { CreateProteinMPNNJobPayload } from 'types';

const PROTEIN_MPNN_PREDICT = (structureId: string) => `/structure/pdb/${structureId}/proteinmpnn`;

export async function createProteinMPNNJob(payload: CreateProteinMPNNJobPayload) {
    const { structureId, sequenceGroupName, numSequences, projectId, fixedPositions, samplingTemp } = payload;
    const url = PROTEIN_MPNN_PREDICT(structureId);
    await axios.post(url, { sequenceGroupName, numSequences, projectId, fixedPositions, samplingTemp });
}
