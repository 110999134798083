import React, { ReactNode } from 'react';

import IcebergIcon from 'icons/iceberg.svg';

import stylesheet from './AuthLayout.module.scss';

interface Props {
  children: ReactNode;
}

export function AuthLayout({ children }: Props): JSX.Element {
  return (
    <div className={stylesheet.root}>
      <div className={stylesheet.container}>
        {children}
        <div className={stylesheet.logoContainer}>
          <img className={stylesheet.logo} src={IcebergIcon} alt="Iceberg Icon" />
        </div>
      </div>
    </div>
  );
}
