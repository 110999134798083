import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, Layout, MenuProps } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';

import { useAuth } from 'hooks';

import { Breadcrumbs } from 'components/Breadcrumbs';
import stylesheet from './MainLayout.module.scss';

export function MainLayout({ children }: { children: React.ReactNode }): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const handleClickLogo = () => {
    navigate('/');
  };

  const handleResetPassword = () => {
    const from = location.pathname !== '/login' ? location : undefined;

    navigate('/login', {
      replace: true,
      state: { from, showResetPasswordForm: true },
    });
  };

  const handleLogout = () => {
    auth?.logout?.();
  };

  const userDropdownItems: MenuProps['items'] = [{
    key: 'reset_passowrd',
    label: 'Reset password',
    onClick: handleResetPassword,
  }, {
    key: 'logout',
    label: 'Logout',
    onClick: handleLogout,
  }];

  return (
    <Layout className={stylesheet.root}>
      <Layout.Header className={stylesheet.header}>
        <div className={stylesheet.logoContainer}>
          <div className={stylesheet.logo} onClick={handleClickLogo}><HomeOutlined style={{ fontSize: 23 }} /> Proteineer Gene Store</div>
          <Breadcrumbs />
        </div>
        <Dropdown
          placement="bottomLeft"
          menu={{ items: userDropdownItems }}
          overlayClassName={stylesheet.userMenu}
        >
          <UserOutlined className={stylesheet.userButton} />
        </Dropdown>
      </Layout.Header>

      <Layout.Content className={stylesheet.content}>
        {children}
      </Layout.Content>
    </Layout>
  );
}
