// Data
import { Columns } from 'components/SequenceTable/columnsData';
// Types
import { ColumnFilter, FilterType } from 'components/SequenceTable/types';

export const getColumnFilterForColumnName = (column: string): ColumnFilter => {
  switch (column) {
    case 'gaps':
    case 'length':
    case 'size':
      return { type: FilterType.NUMBER_RANGE, value: { max: undefined, min: 1 }, bounds: { max: null, min: 1 } };

    case 'nearest_query_structure_rmsd':
      return { type: FilterType.NUMBER_RANGE, value: { max: undefined, min: 0 }, bounds: { max: null, min: 0 } };
    case 'usability':
      return { type: FilterType.NUMBER_RANGE, value: { max: undefined, min: 0 }, bounds: { max: null, min: 0 } };
    case 'thermostability':
    case 'solubility':
    case 'similarity':
    case 'nearest_query_structure_tm':
      return { type: FilterType.NUMBER_RANGE, value: { max: 1, min: 0 }, bounds: { max: 1, min: 0 } };
    case 'ogt_t40':
    case 'ogt_t45':
    case 'ogt_t50':
    case 'ogt_t55':
    case 'ogt_t60':
    case 'ogt_t65':
    case 'identity':
    case 'expression':
      return { type: FilterType.NUMBER_RANGE, value: { max: 1, min: 0 }, bounds: { max: 1, min: 0 } };
    case 'source':
      return { type: FilterType.SOURCE, value: undefined };
    case 'id':
    case 'name':
    case 'nearest_id':
    case 'nearest_query_nearest_id':
    case 'nearest_query_structure_nearest_id':
      return { type: FilterType.SEARCH, value: '' };
    // column with name "tag" stands for all tags
    case 'tag':
      return { type: FilterType.NUMBER_RANGE, value: { max: undefined, min: undefined }, bounds: { max: null, min: null } };
    case 'label':
      return { type: FilterType.LABEL_TAG, value: undefined };
    default:
      return { type: FilterType.NUMBER_RANGE, value: { max: undefined, min: undefined }, bounds: { max: null, min: null } };
  }
};

export const sortOutTags = (a: string, b: string) => {
  const isInOtherListA = Columns[a];
  const isInOtherListB = Columns[b];

  if (isInOtherListA && !isInOtherListB) {
    return -1; // a comes before b
  } else if (!isInOtherListA && isInOtherListB) {
    return 1; // b comes before a
  } else {
    return 0; // maintain the order within elements present or not present
  }
};
