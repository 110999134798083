import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthLayout } from 'layouts';
import { LoginForm } from './LoginForm';
import { UpdatePasswordForm } from './UpdatePasswordForm';

import stylesheet from './Login.module.scss';

export function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showUpdatePasswordForm, setShowUpdatePasswordForm] = useState(location.state?.showUpdatePasswordForm);
  const showResetPasswordForm = location.state?.showResetPasswordForm;

  const handleSubmit = () => {
    if (location.state?.from?.pathname && location.state.from.pathname !== '/login') {
      navigate(location.state.from.pathname, { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  };

  const handleLogin = (showUpdatePasswordForm: boolean) => {
    if (showUpdatePasswordForm) {
      setShowUpdatePasswordForm(true);
    } else {
      handleSubmit();
    }
  };

  const renderLoginForm = () => {
    return (
      <>
        <div className={stylesheet.title}>Login</div>
        <div className={stylesheet.subtitle}>Welcome back! <br />Please enter your details.</div>
        <LoginForm onSubmit={handleLogin} />
      </>
    );
  };

  const renderUpdatePasswordForm = () => {
    return (
      <>
        <div className={stylesheet.title}>Welcome</div>
        <div className={stylesheet.subtitle}>Please update your password.</div>
        <UpdatePasswordForm onSubmit={handleSubmit} action='Update password' />
      </>
    );
  };

  const renderResetPasswordForm = () => {
    return (
      <>
        <div className={stylesheet.title}>Reset password</div>
        <div className={stylesheet.subtitle}>Please set new password.</div>
        <UpdatePasswordForm onSubmit={handleSubmit} action="Save" />
      </>
    );
  };

  return (
    <AuthLayout>
      <div className={stylesheet.formContainer}>
        {showUpdatePasswordForm ? renderUpdatePasswordForm() : showResetPasswordForm ? renderResetPasswordForm() : renderLoginForm()}
      </div>
    </AuthLayout>
  );
}
