export type DockingType = {
  title: string;
  description: JSX.Element | string;
};

type DockingTypeDictionary = Record<string, DockingType>;

export const dockingTypes: DockingTypeDictionary = {
  gnina: {
    title: 'Gnina',
    description: <>
      Docking pipeline based on gnina, assumes that protein is rigid.
      Especially useful for docking very small molecules.
      The most traditional pipeline.
    </>
  },
  'DiffDock_v1.1.2': {
    title: 'DiffDock',
    description: <>
      Docking pipeline based on DiffDock.
      Does not work for some very small molecules.
      Sometimes more diverse results then gnina, but no local search optimization.
      Final scoring is done by gnina.
    </>
  },
  DynamicBind: {
    title: 'DynamicBind',
    description: <>
      Docking pipeline based on DynamicBind.
      Does not assume that protein is rigid.
      Final scoring is done by gnina.
    </>
  }
};
