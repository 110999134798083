import { useEffect, useRef, useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { DeleteOutlined, EnterOutlined } from '@ant-design/icons';
// API
import { sequenceApi } from 'api';
// Components
import { EditProbability } from './components/EditProbability';
// Helpers
import { parseProbabilityValue } from 'helpers/tagFieldsValidation';
// Types
import { SequenceTag } from 'types';
// Styles
import stylesheet from './TagTab.module.scss';

type Props = {
  projectId?: string;
  sequenceId?: string;
  item: {
    uuid: string;
    title: string;
    type: string;
    label_id: number | string;
  };
  removeTagFromList: (id: string) => void;
};

export function TagView({ item, projectId, sequenceId, removeTagFromList }: Props) {
  const componentRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string | number>('');
  const [tagLabels, setTagLabels] = useState<SequenceTag[]>([]);
  const [editing, setEditing] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');

  const pressEnter = (e: any) => {
    const value = e.target.value;

    if (value && item.type !== 'label') {
      const parseValue = parseFloat(e.target.value);
      saveSequenceTagValue(parseValue);
      setValue(parseValue);
    } else if (value) {
      // Look for already exist label
      const preset = tagLabels.find(item => item.title === value);

      if (preset?.id) {
        setValue(preset.title);
        saveSequenceTagValue(preset.id);
      } else {
        setValue(value);
        createNewLabel(value);
      }
    }
  };

  const createNewLabel = (value: string) => {
    projectId && sequenceId && sequenceApi.setTagLabel({ projectId, tagId: item.uuid, title: value })
      .then(data => {
        if (data[0]) {
          setTagLabels((state: any) => {
            return [
              ...state,
              ...data,
            ];
          });
          saveSequenceTagValue(data[0].id);
        }
      })
      .catch(e => {
        setErrorMessage(e.message);
      });
  };

  const saveSequenceTagValue = (newValue: number) => {
    projectId && sequenceId && sequenceApi.setSequenceTagValue({ projectId, tagId: item.uuid, sequenceId, labelId: newValue })
    .then(() => {
      setNewValue('');
      setEditing(false);
    })
    .catch(e => {
      setErrorMessage(e.message);
    });
  };

  const removeTag = () => {
    projectId && sequenceId && sequenceApi.setSequenceTagValue({ projectId, tagId: item.uuid, sequenceId, labelId: '' })
      .then(() => {
        removeTagFromList(item.uuid);
      })
      .catch(e => {
        setErrorMessage(e.message);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  };

  const handleClickOutside = (e: any) => {
    if (componentRef.current && !componentRef.current.contains(e.target) && !e.target.classList.contains('ant-select-item-option-content')) {
      setEditing(false);
    }
  };

  const renderPlaceholder = () => {
    return item.type.charAt(0).toUpperCase() + item.type.slice(1);
  };

  useEffect(() => {
    if (item.type !== 'label') {
      setValue(item.label_id);
    } else {
      projectId && sequenceId && sequenceApi.getTagLabels({ projectId, tagId: item.uuid })
        .then(data => {
          setTagLabels(data);
          const labelValue = data.find(it => it.id === item.label_id);
          if (labelValue) {
            setValue(labelValue.title);
          }
        })
        .catch(() => {});
    }
  }, []);

  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const customRenderOption = (option: SequenceTag) => (
    <AutoComplete.Option type="primary" key={option.id} value={option.title}>
      {option.title}
    </AutoComplete.Option>
  );

  const renderEditForm = () => {
    if (item.type === 'label') {
      return (
        <AutoComplete dataSource={tagLabels.map(customRenderOption)}>
          <Input
            placeholder="Label"
            onPressEnter={pressEnter}
            suffix={<EnterOutlined />}
          />
        </AutoComplete>
      );
    } else if (item.type === 'scoring') {
      return (
        <EditProbability
          setValue={setValue}
          saveSequenceTagValue={saveSequenceTagValue}
        />
      );
    } else {
      return (
        <Input
          autoFocus
          value={newValue}
          placeholder={renderPlaceholder()}
          onChange={handleChange}
          onPressEnter={pressEnter}
          suffix={<EnterOutlined className={stylesheet.enterIcon} />}
        />
      );
    }
  };

  const parseValue = (val: string | number) => {
    if (item.type === 'scoring') {
      return parseProbabilityValue(val as number);
    } else {
      return val;
    }
  };

  return (
    <>
      <div className={stylesheet.tagView}>
        <div
          ref={componentRef}
          className={stylesheet.tagCont}>
          <div
            className={stylesheet.tagTitle}>
            <div className={stylesheet.titleJustify}>{item.title}</div>
            <div>:</div>
          </div>
          <div
            title='Edit value'
            onClick={() => {
              setEditing(true);
            }}
            className={stylesheet.tagValue}>
            {
              editing
              ? renderEditForm()
              : parseValue(value)
            }
          </div>
        </div>
        <DeleteOutlined
          onClick={removeTag}
          className={stylesheet.removeButton} />
      </div>
      {errorMessage && <div className={stylesheet.errorMessage}>{errorMessage}</div>}
    </>
  );
}
