import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, InputRef, Space } from 'antd';
import _ from 'lodash';

import { FilterDropdownProps } from 'antd/es/table/interface';
import { FilterProps, FilterType } from 'components/SequenceTable/types';

import stylesheet from './SearchFilter.module.scss';

type Props = FilterProps<string> & FilterDropdownProps;

export function SearchFilter(props: Props): JSX.Element {
  const [value, setValue] = useState<string>(props.value ?? '');
  const searchInput = useRef<InputRef>(null);

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleApply = (closeDropdown = false) => {
    if (!_.isEmpty(value)) {
      props.onFilter(props.column, { type: FilterType.SEARCH, value });
    } else {
      props.onFilter(props.column, undefined);
    }

    props.confirm({ closeDropdown });
  };

  const handleClear = () => {
    setValue('');
    props.onFilter(props.column, undefined);
  };

  const handleReset = () => {
    setValue('');
    props.onFilterReset(props.column);
    props.confirm();
  };

  useEffect(() => {
    if (props.visible) {
      setValue(props.visible ? props.value ?? '' : '');
    }

    // todo: fix autofocus issue for case: open and hide the same filter dropdown 2 or more times
    // if (props.visible) {
    //   console.info('focus', searchInput);
    //   searchInput.current?.focus();
    // }
  }, [props.visible]);

  return (
    <div style={{ padding: 8 }}>
      <Input
        className={stylesheet.input}
        ref={searchInput}
        autoFocus={true}
        value={value}
        placeholder={`Search ${props.column}`}
        onChange={handleChangeValue}
        onPressEnter={() => handleApply()}
      />
      <Space>
        <Button
          size="small"
          className={stylesheet.button}
          onClick={() => handleReset()}
        >
          Reset
        </Button>
        <Button
          size="small"
          className={stylesheet.button}
          onClick={() => handleClear()}
        >
          Clear
        </Button>
        <Button
          size="small"
          type="primary"
          className={stylesheet.button}
          onClick={() => handleApply(true)}
        >
          Apply
        </Button>
      </Space>
    </div>
  );
}

export function getSearchFilter(props: FilterProps<string>) {
  return function SearchFilterWrapper(dropdownProps: FilterDropdownProps): JSX.Element {
    return (<SearchFilter {...props} {...dropdownProps} />);
  };
}
