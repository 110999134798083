export default [
  {
    label: 'All',
    key: '0',
  },
  {
    label: 'Thermostability',
    key: '1',
    identifier: 'thermostability',
  },
  {
    label: 'Similarity',
    key: '2',
    identifier: 'similarity',
  },
  {
    label: 'Solubility',
    key: '3',
    identifier: 'solubility',
  },
  {
    label: 'Expression',
    key: '4',
    identifier: 'expression',
  },
  {
    label: 'Usability',
    key: '5',
    identifier: 'usability',
  }
] as { label: string, key: string, identifier: string }[];
