import { Link } from 'react-router-dom';

export const parseSourceTitle = (title: string) => {
  /* Search for pattern link */
  const regExp = /(JP|KR)\s*\d+-[A-Z]\/|(WO|US|EP)\s*\d+/g;
  const patents = title.match(regExp);

  if (!patents) return title;

  const split = title.split(patents[0]);

  return (
    <>
      {split[0]}
      <Link to={`https://patents.google.com/?q=${patents[0].replace(/ /g, '')}`} target="_blank">{patents[0]}</Link>
      {split[1]};
    </>
  );
};
