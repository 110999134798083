import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Plotly from 'react-plotly.js';
import cx from 'classnames';

import { Hints } from 'components/SequenceTable/columnsData';
import { TitleTooltip } from 'components/TitleTooltip';

import stylesheet from './Historgam.module.scss';

interface Layout {
  yaxis: {
    type: string;
    autorange: boolean;
  };
}

interface Props {
  className?: string;
  title: string;
  data: Array<number>;
  layoutOptions?: Layout | any;
  type?: string | null;
  name: string;
  setSelectedRange: (data: number[], name: string) => void;
  secondaryData?: Array<number>;
  removeSelectedKey: (name: string) => void;
}

export function Histogram({ className, title, data, layoutOptions = {}, type = null, setSelectedRange, name, secondaryData, removeSelectedKey }: Props): JSX.Element {
  const container = useRef<HTMLDivElement>(null);
  const hintsList = Hints as Record<string, string | JSX.Element>;

  const [size, setSize] = useState({ width: 0, height: 0 });
  const [xRange, setXRange] = useState<number[] | null>(null);

  const handleResize = () => {
    if (data && container.current) {
      const rect = container.current.getBoundingClientRect();
      setSize({ height: rect.height - 70, width: rect.width });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    handleResize();
  }, []);

  const foreground = '#1f77b4';
  const background = '#a8d2f0';
  const list = secondaryData
    ? [{ x: data, type: 'histogram', showlegend: false, marker: { color: background } }, { x: secondaryData, type: 'histogram', marker: { color: foreground }, showlegend: false }]
    : [{ x: data, type: 'histogram', marker: { color: foreground } }];

  return (
    <div className={cx(stylesheet.container, className)}>
      <div
        ref={container}
        className={stylesheet.histogram}>
        <div className={stylesheet.histogramHint}>
          <TitleTooltip title={title} hint={hintsList[name]} className='leftMargin'/>
        </div>
        <Plotly
          onRelayout={(massive) => {
            if (massive['xaxis.range[0]'] && massive['xaxis.range[1]']) {
              setXRange([massive['xaxis.range[0]'], massive['xaxis.range[1]']]);
              setSelectedRange([massive['xaxis.range[0]'], massive['xaxis.range[1]']], name);
            };

            if (massive['xaxis.autorange'] && massive['yaxis.autorange'] && secondaryData) {
              removeSelectedKey(name);
              setXRange(null);
            }
          }}
          data={list as any}
          layout={{
            width: size.width,
            height: size.height,

            barmode: secondaryData ? 'overlay' : '', // Overlay histograms
            barnorm: '', // Normalize each histogram independently

            margin: {
              l: 40,
              r: 20,
              b: 20,
              t: 0,
              pad: 4
            },
            ...layoutOptions,
            ...xRange ? { xaxis: { range: xRange } } : {},
            ...type ? { yaxis: { type } } : {}
          }}
        />
      </div>
    </div>
  );
}
