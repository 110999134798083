import { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import { useAuth } from 'hooks';
import { UpdatePasswordPayload } from 'types';

import { FORM_VALIDATION_RULES } from './helpers';

import stylesheet from './Login.module.scss';

interface Props {
  onSubmit: () => void;
  action: string;
}

export function UpdatePasswordForm({ onSubmit, action }: Props): JSX.Element {
  const auth = useAuth();

  const [form] = Form.useForm();
  const [error, setError] = useState(false);

  const handleFromChange = () => {
    setError(false);
  };

  const handleSubmit = (payload: UpdatePasswordPayload) => {
    auth?.updatePassword && auth.updatePassword(payload)
      .then(() => {
        onSubmit();
      })
      .catch(() => {
        setError(error);
      });
  };

  return (
    <Form
      id="updatePasswordForm"
      name="updatePasswordForm"
      form={form}
      requiredMark={false}
      onChange={handleFromChange}
      onFinish={handleSubmit}
    >
      <Form.Item name="password" rules={FORM_VALIDATION_RULES.PASSWORD}>
        <Input.Password
          id="new-password"
          autoComplete="new-password"
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Password"
        />
      </Form.Item>

      {error && <div className={stylesheet.error}>Some error occurred.</div>}

      <Form.Item>
        <Button
          className={stylesheet.loginButton}
          type="primary"
          htmlType="submit"
        >
          {action}
        </Button>
      </Form.Item>
    </Form>
  );
}
