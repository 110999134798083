import _ from 'lodash';
import { getFilterIcon } from './icons';
// Types
import { ColumnFilter, FilterCollection, NumberRangeFilter, OnFilter, OnFilterReset, SourceFilterValue } from './types';
import { Cluster, SequenceColumn, SequenceData, SequenceTag, Tag, Config } from 'types';
// Components
import { NameCellRenderer } from './cells';
import { getColumns, getGenericTagColumn, renderPinedColumn } from './columnsData';
// Helpers
import {
  getNumberRangeFilter,
  getSearchFilter,
  getSourceFilter,
  getLabelTagFilter,
} from './filters';
import { getColumnFilterForColumnName } from 'components/FiltersControl/helpers';

export function getPreparedColumns({
  filter,
  onFilter,
  onFilterReset,
  allData,
  handleNameEdition,
  columnsAvailability,
  projectTags,
  groupId,
  frontendConfig,
  labelTagsValues,
  tagColumnsList,
  pinedSeq,
}: {
  filter: FilterCollection;
  onFilter: OnFilter;
  onFilterReset: OnFilterReset;
  allData: SequenceData;
  handleNameEdition: (value: string, seq_id: string) => void;
  columnsAvailability: Record<string, boolean>;
  projectTags?: Tag[];
  groupId: number;
  frontendConfig: Config['frontendConfig'];
  labelTagsValues?: Record<string, SequenceTag[]>;
  tagColumnsList?: Record<string, boolean>;
  pinedSeq: SequenceColumn | undefined;
}) {
  const tagColumns = projectTags?.map((item) => getGenericTagColumn(item, labelTagsValues));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const columns = [...getColumns, ...tagColumns].map(item => renderPinedColumn(item, pinedSeq));

  return _.map(columns.filter((item: any) => !!columnsAvailability[item.key]), (column) => {
    let filterType = column.key;
    let filterKey = column.key;

    // Figure out if column is a tag column
    if (labelTagsValues?.[column.dataIndex]) {
      filterType = 'tag';
      filterKey = column.dataIndex;
    }
    // Figure out if column is a tag column
    if (_.has(tagColumnsList, column.key)) {
      filterKey = column.dataIndex;
    }

    let columnFilter;
    let filterDropdown;
    switch (filterType) {
      case 'id':
      case 'name':
      case 'nearest_query_nearest_id':
      case 'nearest_query_structure_nearest_id':
        filterDropdown = getSearchFilter({
          column: column.key,
          columnTitle: column.title || '',
          value: filter[filterKey]?.value as any,
          onFilter: (_, columnFilter: ColumnFilter | undefined) => {
            onFilter(filterKey, columnFilter);
          },
          onFilterReset,
          allData,
          dataIndex: column.dataIndex,
          labelTagsValues,
          });
        break;
      case 'gaps':
      case 'size':
      case 'similarity':
      case 'length':
      case 'expression':
      case 'thermostability':
      case 'solubility':
      case 'usability':
      case 'nearest_query_structure_tm':
      case 'nearest_query_structure_rmsd':
      case 'ogt_t40':
      case 'ogt_t45':
      case 'ogt_t50':
      case 'ogt_t55':
      case 'ogt_t60':
      case 'ogt_t65':
        columnFilter = getColumnFilterForColumnName(column.key) as NumberRangeFilter;
        filterDropdown = getNumberRangeFilter({
          column: column.key,
          columnTitle: column.title || '',
          value: filter[filterKey]?.value ?? columnFilter,
          bounds: columnFilter.bounds,
          onFilter: (_, columnFilter: ColumnFilter | undefined) => {
            onFilter(filterKey, columnFilter);
          },
          onFilterReset,
          allData,
          dataIndex: column.dataIndex,
          labelTagsValues,
          });
        break;
      case 'source':
        filterDropdown = getSourceFilter({
          column: column.key,
          columnTitle: column.title || '',
          value: filter[filterKey]?.value as SourceFilterValue,
          onFilter: (_, columnFilter: ColumnFilter | undefined) => {
            onFilter(filterKey, columnFilter);
          },
          onFilterReset,
          allData,
          dataIndex: column.dataIndex,
          labelTagsValues,
          });
        break;

      // column with name "tag" stands for all tags
      case 'tag':
        filterDropdown = getLabelTagFilter({
          column: column.key,
          columnTitle: column.title || '',
          value: filter[filterKey]?.value as any,
          onFilter: (_, columnFilter: ColumnFilter | undefined) => {
            onFilter(filterKey, columnFilter);
          },
          onFilterReset,
          allData,
          dataIndex: column.dataIndex,
          labelTagsValues,
          });
        break;
      default:
        filterDropdown = getNumberRangeFilter({
          column: column.key,
          columnTitle: column.title || '',
          value: filter[filterKey]?.value as any,
          onFilter: (_, columnFilter: ColumnFilter | undefined) => {
            onFilter(filterKey, columnFilter);
          },
          onFilterReset,
          allData,
          dataIndex: column.dataIndex,
          labelTagsValues,
          });
    }

    return {
      ...column,
      // Provide addition method for sequence name column
      ...column.key === 'name' ? { render: (text: string, row: SequenceColumn & Cluster) => NameCellRenderer(text, row, groupId, handleNameEdition, '', frontendConfig?.readOnly) } : {},
      filterIcon: getFilterIcon(!_.isEmpty(filter[column.key])),
      filterDropdown,
      // Disabled default sorting if server sorting activeted
      // Check if server sorting available for this column
      sorter: {},
      width: column.width ?? 100
    };
  });
}
