import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { MainLayout } from 'layouts';
import { Loader } from 'components';
import { useAuth } from 'hooks';

export function ProtectedRoute(): JSX.Element {
  const auth = useAuth();
  const location = useLocation();

  if (auth.initLoading) {
    return <Loader />;
  }

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
}
