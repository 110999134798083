import { useContext, useEffect, useRef, useState } from 'react';

import { Button, Empty, List, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import cx from 'classnames';
import _ from 'lodash';
// Molstar
import { createPluginUI } from 'molstar/lib/mol-plugin-ui/react18';
import { PluginContext } from 'molstar/lib/mol-plugin/context';
import { PluginUIContext } from 'molstar/lib/mol-plugin-ui/context';
import { MolScriptBuilder as MS } from 'molstar/lib/mol-script/language/builder';
import { BuiltInTrajectoryFormat } from 'molstar/lib/mol-plugin-state/formats/trajectory';
import { Asset } from 'molstar/lib/mol-util/assets';
// API
import { sequenceApi } from 'api';
// Helpers
import { getStructures } from 'helpers/getStructures';
// Types
import { LigandDocking, Sequence, Structure } from 'types';
// Contexts
import { ConfigContext } from 'src/contexts/ConfigContext';
// Styles
import stylesheet from './DockingTab.module.scss';
import { NewDockingForm } from 'components/SequenceInfo/Tabs/DockingTab/NewDockingForm';

type Props = {
  projectId: string;
  sequence: Sequence;
  structures: Structure[];
  loading: boolean;
};

interface Model {
  file: string;
  format: BuiltInTrajectoryFormat;
  identifier: string;
  isLink: boolean;
}

declare global {
  interface Window {
    docking?: PluginUIContext;
  }
}

export function DockingTab({ projectId, sequence, loading, structures }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const { ligands } = useContext(ConfigContext);
  const [structureLoading, setStructureLoading] = useState(false);
  const [ligandDocking, setLigandDocking] = useState<LigandDocking[]>([]);

  useEffect(() => {
    sequenceApi.getSequenceDocking({ projectId, sequenceId: sequence.id }).then((data) => {
      setLigandDocking(data);
    }).catch((error) => {
      console.error('Get sequence docking data error:', error);
    });
  }, []);

  function chainSelection(auth_asym_id: string) {
    return MS.struct.generator.atomGroups({
      'chain-test': MS.core.rel.eq([MS.struct.atomProperty.macromolecular.auth_asym_id(), auth_asym_id])
    });
  }

  async function loadStructure(plugin: PluginContext, url: string, format: BuiltInTrajectoryFormat, isLink: boolean, identifier?: string) {
    const data = isLink
      ? await plugin.builders.data.download({ url: Asset.Url(url) })
      : await plugin.builders.data.rawData({ data: url, label: identifier });

    const trajectory = await plugin.builders.structure.parseTrajectory(data, format);
    const model = await plugin.builders.structure.createModel(trajectory);
    // eslint-disable-next-line no-void
    const structure = await plugin.builders.structure.createStructure(model);

    return { data, trajectory, model, structure };
  }

  const buildStaticSuperposition = async (plugin: PluginContext, src: Model[]) => {
    return await plugin.dataTransaction(async () => {
      for (const s of src) {
        const { structure } = await loadStructure(plugin, s.file, s.format, s.isLink, s.identifier);
        const chain = await plugin.builders.structure.tryCreateComponentFromExpression(structure, chainSelection('A'), 'Chain A');
        if (chain) await plugin.builders.structure.representation.addRepresentation(chain, { type: 'cartoon' });
      }
    });
  };

  const buildCombinedStructure = (sdf: string, pdb: { url: string, isLink: boolean }) => {
    async function init() {
      const elem = document.getElementById('molstar-container');
      if (elem) {
        window.docking = await createPluginUI(elem as HTMLDivElement);

        const files = [{
          file: pdb.url,
          format: 'pdb' as BuiltInTrajectoryFormat,
          identifier: 'pdb',
          isLink: pdb.isLink,
        }, {
          file: sdf,
          format: 'sdf' as BuiltInTrajectoryFormat,
          identifier: 'sdf',
          isLink: false,
        }];
        await buildStaticSuperposition(window.docking, files);
      }
    }

    void init();
  };

  const downloadSDFfile = (payload: LigandDocking) => {
    const structure = structures.find(item => item.id === payload.structure_uuid);
    const relatedStructure = structure && getStructures([structure])[0];

    Promise.all([
      sequenceApi.getLigandSDFfile({ projectId, structureId: payload.structure_uuid, type: payload.type, ligandId: payload.ligand_uuid, poseId: payload.pose_id }),
      // Fetch pdb file if not founed or it's type is proteineer
      (!relatedStructure || relatedStructure.external_id === undefined) && sequenceApi.getStructurePdb({ structureId: payload.structure_uuid })
    ]).then(([sdf, pdb]) => {
      setStructureLoading(false);
      _.delay(() => {
        const pdbFile = {
          url: (pdb || relatedStructure?.ngl_link) as string,
          isLink: relatedStructure?.external_id !== undefined
        };

        const blob = new Blob([sdf], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `PROTEINEER-${payload.structure_uuid}-${payload.ligand_uuid}-${payload.pose_id}.sdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        if (pdb !== undefined && pdb) {
          const blob = new Blob([pdb], { type: 'text/plain' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `PROTEINEER-${payload.structure_uuid}.pdb`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        } else {
          const a = document.createElement('a');
          a.href = pdbFile.url;
          a.download = `PROTEINEER-${payload.structure_uuid}-E.pdb`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
      }, 100);
    }).catch((error) => {
      console.error('Get pdb and sdf files error:', error);
    });
  };

  const getSDFfile = (payload: LigandDocking) => {
    clearStructureContent();
    setStructureLoading(true);

    const structure = structures.find(item => item.id === payload.structure_uuid);
    const relatedStructure = structure && getStructures([structure])[0];

    Promise.all([
      sequenceApi.getLigandSDFfile({ projectId, structureId: payload.structure_uuid, type: payload.type, ligandId: payload.ligand_uuid, poseId: payload.pose_id }),
      // Fetch pdb file if not founed or it's type is proteineer
      (!relatedStructure || relatedStructure.external_id === undefined) && sequenceApi.getStructurePdb({ structureId: payload.structure_uuid })
    ]).then(([sdf, pdb]) => {
      setStructureLoading(false);
      _.delay(() => {
        const pdbFile = {
          url: (pdb || relatedStructure?.ngl_link) as string,
          isLink: relatedStructure?.external_id !== undefined
        };

        buildCombinedStructure(sdf, pdbFile);
      }, 100);
    }).catch((error) => {
      console.error('Get pdb and sdf files error:', error);
    });
  };

  const parseValue = (val?: number) => {
    if (val === undefined) return 'N/A';
    if (val === null) return 'N/A';
    return val.toFixed(5);
  };

  const renderItem = (payload: LigandDocking) => {
    // Get name of ligand
    const name: string = ligands[projectId].find(item => item.id === payload.ligand_uuid)?.name ?? '';

    return (
      <List.Item key={payload.ligand_uuid + payload.type + payload.structure_uuid} className={stylesheet.item}>
        <>{name || payload.ligand_uuid} / </>
        {payload.type} /
        <> Pose {payload.pose_id}: </>
        <>CNN Score: {parseValue(payload.gnina_cnn_score)}, CNN Affinity: {parseValue(payload.gnina_cnn_affinity)}</>
        <Button
          className={stylesheet.viewBtn}
          loading={loading}
          type="default"
          size='small'
          onClick={() => getSDFfile(payload)}
        >
          View
        </Button>
        <Button
          className={stylesheet.downloadBtn}
          loading={loading}
          type="default"
          size='small'
          onClick={() => downloadSDFfile(payload)}>
          Download
        </Button>
      </List.Item>
    );
  };

  const clearStructureContent = () => {
    // Clear the content by setting innerText to an empty string
    if (ref.current) {
      // Clear the content by setting innerText to an empty string
      ref.current.innerText = '';
    }
  };

  useEffect(() => {
    return () => {
      window.docking?.dispose();
      window.docking = undefined;
      clearStructureContent();
    };
  }, [sequence]);

  const rightBlock = cx({
    [stylesheet.structureContainer]: !_.isEmpty(sequence.ligand_docking)
  });
  const leftBlock = cx({
    [stylesheet.list]: true,
    [stylesheet.fullScreen]: _.isEmpty(sequence.ligand_docking)
  });

  if (loading) {
    return (
      <div className={stylesheet.dockingTab}>
        <div className={stylesheet.lhs}>
          <List loading={loading} className={leftBlock} />
        </div>
        <div className={rightBlock}></div>
      </div>
    );
  }

  if (structures.length === 0) {
    void sequenceApi.createStructurePrediction(sequence.id);
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={(<span>No Structures Found</span>)}
      />
    );
  }

  return (
    <div className={stylesheet.dockingTab}>
      <div className={stylesheet.lhs}>
        <List
          loading={loading}
          className={leftBlock}
          dataSource={ligandDocking}
          renderItem={renderItem}
          locale={{ emptyText: <></> }}
        />
        <NewDockingForm
          projectId={projectId}
          sequenceId={sequence.id}
          />
      </div>
      {structureLoading
        ? <div className={rightBlock}>
          <Spin indicator={(
            <LoadingOutlined style={{ fontSize: 28 }} spin />
          )}/>
        </div>
        : <div id="molstar-container" ref={ref} className={rightBlock} />}
    </div>
  );
}
