import { axios } from './index';

import { User } from '../types';

const GET_USER_URL = '/users';
export async function getUser() {
  const result = await axios.get(GET_USER_URL);

  return result.data as User;
}
