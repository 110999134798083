import { lazy } from 'react';

export { EmptyPage } from './EmptyPage';
export { ErrorPage } from './ErrorPage';
export { Login } from './Login';

export const Groups = lazy(async () =>
  await import(/* webpackChunkName: 'page-groups' */ './Groups')
    .then(({ Groups }) => ({ default: Groups }))
);

export const GroupView = lazy(async () =>
  await import(/* webpackChunkName: 'page-group-details' */ './GroupView')
    .then(({ GroupView }) => ({ default: GroupView }))
);

export const Projects = lazy(async () =>
  await import(/* webpackChunkName: 'page-projects' */ './Projects')
    .then(({ Projects }) => ({ default: Projects }))
);

export const SequenceView = lazy(async () =>
  await import(/* webpackChunkName: 'page-sequence-details' */ './SequenceView')
    .then(({ SequenceView }) => ({ default: SequenceView }))
);

export const ClusterView = lazy(async () =>
  await import(/* webpackChunkName: 'page-cluster-details' */ './ClusterView')
    .then(({ ClusterView }) => ({ default: ClusterView }))
);

export const StructureView = lazy(async () =>
  await import(/* webpackChunkName: 'page-sequence-details' */ './StructureView')
    .then(({ StructureView }) => ({ default: StructureView }))
);

export const CompareView = lazy(async () =>
  await import(/* webpackChunkName: 'page-compare-details' */ './CompareView')
    .then(({ CompareView }) => ({ default: CompareView }))
);

export const MSAView = lazy(async () =>
  await import(/* webpackChunkName: 'page-msa-details' */ './MSAView')
    .then(({ MSAView }) => ({ default: MSAView }))
);
