import { Location } from 'types';
import _ from 'lodash';
// Helpers
import { buildLink } from './buildLink';

export const formatLocationLinks = (data: Location[]) => {
  if (_.isEmpty(data)) return [];

  return data.map(({ source, external_id, title, coordinate }: Location) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const coords = coordinate?.lat && coordinate.long && `${coordinate?.lat} ${coordinate?.long}`;

    return ({
      id: external_id ?? '',
      source: source ?? '',
      link: external_id ? buildLink(`https://www.ncbi.nlm.nih.gov/biosample/?term=${external_id}`, external_id) : '',
      title: title?.trim() ?? '',
      coords: coords ? buildLink(`https://www.google.de/maps?q=${coords}`, coords) : ''
    });
  });
};
