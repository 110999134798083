import { Button, Input, Modal } from 'antd';
import { useContext, useState } from 'react';
import stylesheet from 'components/SequenceInfo/Tabs/TagsTab/TagCreate.module.scss';
import { PlusOutlined } from '@ant-design/icons';
import { putProjectLigands } from 'api/sequence';
import { ConfigContext } from '../../../../contexts/ConfigContext';

type Props = {
  projectId: string;
};

export function LigandCreate({ projectId }: Props) {
  const { refreshLigands } = useContext(ConfigContext);
  const [opened, setOpened] = useState(false);
  const [name, setName] = useState('');
  const [smiles, setSmiles] = useState('');
  const [smilesStatus, setSmilesStatus] = useState< '' | 'error' | 'warning' | undefined>('');
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

  const handleCancel = () => {
    setName('');
    setSmiles('');
    setOpened(false);
    setErrorMessage('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSmilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const n = e.target.value.trim();
    if (!/^[A-Za-z()#=[\]+.\-0-9@\\/]*$/.test(n)) {
      setSmilesStatus('error');
      setErrorMessage(<>
        Invalid smiles. Use a canoncial smiles string. For example from <a href="https://pubchem.ncbi.nlm.nih.gov/" rel="noreferrer" target="_blank">PubChem</a>.
      </>);
    } else {
      setSmilesStatus(undefined);
      setErrorMessage('');
    }
    setSmiles(n);
  };

  const handleCreate = () => {
    putProjectLigands({ projectId, payload: { name, smiles } }).then(() => {
      handleCancel();
      setTimeout(() => {
        refreshLigands();
      }, 1000);
    }).catch(e => {
      setErrorMessage(e.message);
    });
  };

  return (
    <>
      <Button
        className={stylesheet.addNewButton}
        icon={<PlusOutlined />}
        onClick={() => {
          setOpened(true);
        }}>
        Add new Ligand
      </Button>

      <Modal
        title="Add new Ligand"
        onCancel={handleCancel}
        open={opened}
        width={550}
        style={{ minHeight: '400px' }}
        footer={[
          <div key="buttons">
            <Button key="cancel" onClick={handleCancel}>Cancel</Button>
            <Button
              key="create"
              disabled={!name || !smiles || (!!errorMessage) || name.length === 0 || smiles.length === 0}
              onClick={handleCreate}
              type="primary">
              Create
            </Button>
          </div>
        ]}
      >
        <div className={stylesheet.contentForm}>
          <div className={stylesheet.tagValueCont}>
            <div className={stylesheet.inputs}>
              <Input
                value={name}
                placeholder='Name'
                onChange={handleChange}
                required
              />
              <Input
                value={smiles}
                placeholder='Smiles'
                onChange={handleSmilesChange}
                status={smilesStatus}
                required
              />
            </div>
            <div style={{ minHeight: '50px' }}>
              {errorMessage && <div className={stylesheet.errorMessage}>{errorMessage}</div>}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
