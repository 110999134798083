import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import _ from 'lodash';
// API
import { sequenceApi } from 'api';
// Types
import { Config, Ligand } from 'types';

interface ConfigContextType {
  configs: Record<string, Config>;
  ligands: Record<string, Ligand[]>;
  refreshLigands: () => void;
}

const ConfigContext = createContext<ConfigContextType>({ configs: {}, ligands: {}, refreshLigands: () => {} });

const ConfigContextProvider = ({ children }: any) => {
  const [configs, setConfigs] = useState<Record<string, Config>>({});
  const [ligands, setLigands] = useState<Record<string, Ligand[]>>({});
  const [projectId, setProjectId] = useState<string>('');

  const location = useLocation();

  const fetchProjectConfig = () => {
    sequenceApi.getProjectConfig({ projectId })
      .then(data => {
        setConfigs(state => {
          return {
            ...state,
            [projectId]: data
          };
        });
      })
      .catch(error => console.error('Get project config error:', error));
  };

  const refreshLigands = () => {
    if (!projectId) {
      console.error('Project ID is not set');
      return;
    }
    sequenceApi.getProjectLigands({ projectId })
      .then(data => {
        setLigands(state => {
          return {
            ...state,
            [projectId]: data
          };
        });
      })
      .catch(error => console.error('Get project ligends error:', error));
  };

  useEffect(() => {
    // This effect will run whenever the URL changes
    const urlSegments = location.pathname.split('/');
    // Find the index of the 'projects' segment
    const projectsIndex = urlSegments.indexOf('projects');
    // Extract the ID following the 'projects' segment
    const pid = urlSegments[projectsIndex + 1];
    setProjectId(pid);
  }, [location.pathname]);

  useEffect(() => {
    if (projectId && !_.has(configs, projectId)) {
      fetchProjectConfig();
    }
    // Fetch ligands if not stored
    if (projectId && !_.has(ligands, projectId)) {
      refreshLigands();
    }
  }, [projectId]);

  return (
    <ConfigContext.Provider value={{ configs, ligands, refreshLigands }}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigContextProvider };
