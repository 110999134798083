import { useState } from 'react';
import { Button, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
// API
import { sequenceApi } from 'api';

type Props = {
  disabled?: boolean;
  className?: string;
  groupId: number;
  projectId: string;
  sequenceGroup?: string;
};

export function DownloadButton({ className, groupId, projectId, sequenceGroup = '' }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleDownload = async () => {
    setLoading(true);

    sequenceApi.getSequencesFasta({ groupId, projectId, contentType: 'text/x-fasta' })
      .then((data: any) => {
        const blob = new Blob([data], { type: 'text/fasta;charset=utf-8' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${sequenceGroup ?? 'sequences_group'}.fasta`;

        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        setLoading(false);
      })
      .catch((error) => {
        console.error('Gets sequences fasta error:', error);

        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
      <Button
        id='downloadButton'
        className={className}
        loading={loading}
        type="primary"
        icon={<DownloadOutlined />}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleDownload}
      >
        Download
      </Button>
      <Modal
        title="Warning"
        open={error}
        onCancel={() => setError(false)}
        footer={null}
      >
        <p>Please select a sequence</p>
      </Modal>
    </>
  );
}
