import Axios from 'axios';

import * as authApi from './auth';
import * as sequenceApi from './sequence';
import * as userApi from './user';

const BACKEND_BASE_URL = process.env.REACT_APP_DNHAY_BASE_URL as string;
if (BACKEND_BASE_URL[BACKEND_BASE_URL.length - 1] !== '/') {
  throw new Error('BACKEND_BASE_URL must end with \'/\'');
}

const SEQUENCES_LIMIT = process.env.REACT_APP_SEQUENCES_LIMIT as string;
const STATISTICS_LIMIT = process.env.REACT_APP_STATISTICS_LIMIT as string;

const axios = Axios.create({
  baseURL: BACKEND_BASE_URL,
  timeout: 275000, // delay for 10 retries, increesed each try by 1
  withCredentials: true,
});

export {
  axios,

  authApi,
  sequenceApi,
  userApi,

  BACKEND_BASE_URL,
  SEQUENCES_LIMIT,
  STATISTICS_LIMIT,
};
