import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input, InputRef } from 'antd';
import cx from 'classnames';
// API
import { sequenceApi } from 'api';
// Types
import { Cluster, Sequence } from 'types';
// Styles
import stylesheet from './NameCellRenderer.module.scss';

export function NameCellRenderer(name: string, sequence: Sequence & Cluster | undefined, groupId: number, handleNameEdition?: any, styles?: string, readOnly: boolean = false): JSX.Element {
  const params = useParams();

  const inputWrapper = useRef<HTMLDivElement>(null);
  const input = useRef<InputRef>(null);

  const [value, setValue] = useState(name ?? '');
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const projectId = params.projectId as string;
  const id = sequence?.id;
  const cluster_id = sequence?.cluster_id;

  useEffect(() => {
    setValue(name);
  }, [name]);

  const handleClick = (e: any) => {
    /* Stop Cell selection if it's subcluster */
    cluster_id && e.stopPropagation();
    setEditing(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSave = () => {
    setLoading(true);
    handleNameEdition?.(value, id);

    /* Save sequence name */
    !cluster_id && id && sequenceApi.setSequenceName({ projectId, sequenceId: id, name: value ?? '' })
      .then(() => {
        setLoading(false);
        setEditing(false);
      })
      .catch((error) => {
        console.error('Set sequence name failed:', error);

        setLoading(false);
        setEditing(false);
        setValue(name);
      });

    /* Save cluster name */
    cluster_id && groupId && sequenceApi.updateClusterInfo({ projectId, clusterId: cluster_id, title: value ?? '', groupId })
      .then(() => {
        setLoading(false);
        setEditing(false);
      })
      .catch((error) => {
        console.error('Set sequence name failed:', error);

        setLoading(false);
        setEditing(false);
        setValue(name);
      });

    setEditing(false);
  };

  const handleEscapePress = (event: KeyboardEvent) => {
    if (editing && event.key === 'Escape') {
      setValue(name);
      setEditing(false);
    }
  };

  // const handleOutsideClick = (event: MouseEvent) => {
  //   if (editing && !inputWrapper.current?.contains(event.target as Node)) {
  //     setValue(name);
  //     setEditing(false);
  //   }
  // };

  useEffect(() => {
    editing && input?.current?.focus();

    if (editing) {
      // window.addEventListener('click', handleOutsideClick);
      window.addEventListener('keydown', handleEscapePress);
    }

    return () => {
      if (!editing) {
        // window.removeEventListener('click', handleOutsideClick);
        window.removeEventListener('keydown', handleEscapePress);
      }
    };
  }, [editing]);

  if (editing) {
    return (
      <div className={cx(stylesheet.editableCell, styles)} ref={inputWrapper}>
        <Input
          ref={input}
          value={value}
          disabled={loading}
          onChange={handleChange}
          onPressEnter={handleSave}
          onBlur={handleSave}
        />
      </div>
    );
  }

  return (
    <div className={cx(stylesheet.cell, styles, readOnly && stylesheet.disabled)} onClick={(e) => !readOnly ? handleClick(e) : {}}>
      {value}
    </div>
  );
}
