import { useContext, useState } from 'react';
import { Button, Divider, Select } from 'antd';

// Types
import { Ligand } from 'types';
// Styles
import stylesheet from './DockingTab.module.scss';
import { dockingTypes } from 'common/DockingTypes';
import _ from 'lodash';
import { RadarChartOutlined } from '@ant-design/icons';
import { LigandCreate } from 'components/SequenceInfo/Tabs/DockingTab/LigandCreate';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import { putSequenceDocking } from 'api/sequence';
import { TitleTooltip } from 'components/TitleTooltip';

type Props = {
  projectId: string;
  sequenceId?: string;
};

export function NewDockingForm({ projectId, sequenceId }: Props) {
  const { ligands } = useContext(ConfigContext);
  const [ligandId, setLigandId] = useState('');
  const [dockingType, setDockingType] = useState<string>('');
  const [open, setOpen] = useState(false);

  const renderLigandOption = (list: Ligand[]) => {
    return list.map(item => {
      return (
        <Select.Option
          key={item.id}
          value={item.id}
          label={item.name}
          title={item.name}
        >
          <div className={stylesheet.optionContainer}>
            <span>{item.name}</span>
          </div>
        </Select.Option>
      );
    });
  };

  const renderDockingTypeOption = () => {
    return _.keys(dockingTypes).map(key => {
      return (
        <Select.Option
          key={key}
          value={key}
          label={dockingTypes[key].title}
          title={dockingTypes[key].title}
        >
          <div className={stylesheet.optionContainer}>
            <div>{dockingTypes[key].title}</div>
            <TitleTooltip hint={dockingTypes[key].description} className='leftMargin' />
          </div>
        </Select.Option>
      );
    });
  };

  const handleSubmit = () => {
    if (!sequenceId) {
      console.error('Sequence ID is missing');
      return;
    }
    putSequenceDocking({ projectId, sequenceId, payload: { ligand_uuid: ligandId, type: dockingType } }).then(() => {
      setLigandId('');
      setDockingType('');
    }).catch(e => {
      console.error(e.message);
    });
  };

  return (
    <>
      <div className={stylesheet.newDockingForm}>
          <Select
            open={open}
            onDropdownVisibleChange={(visible) => setOpen(visible)}
            style={{ width: '40%' }}
            placeholder="Ligands"
            value={ligandId || null}
            onChange={(ligandId) => {
              setLigandId(ligandId);
            }}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: '8px 0',
                  }}
                />
                <LigandCreate projectId={projectId} />
              </>
            )}
          >
            {renderLigandOption(ligands[projectId])}
          </Select>
          <Select
            style={{ width: '40%' }}
            placeholder="Docking Type"
            value={dockingType || null}
            onChange={(value) => {
              setDockingType(value);
            }}
          >
            {renderDockingTypeOption()}
          </Select>
          <Button
            style={{ width: 'calc(20% - 20px)' }}
            type="primary"
            icon={<RadarChartOutlined />}
            disabled={!ligandId || !dockingType}
            onClick={handleSubmit}
          >
            <span>Start Docking</span>
          </Button>
        </div>
    </>
  );
}
