import _ from 'lodash';

import {
  SourceFilterCondition,
} from './filters';

import { FilterCollection, FilterType } from './types';
import { Sequence, SequenceData, SequenceColumn } from 'types';
import { DataSource, dataSources } from 'common/DataSources';

export function getDefaultSorter(key: keyof SequenceColumn) {
  return (a: SequenceColumn, b: SequenceColumn) => {
    if (a[key] === undefined && b[key] === undefined) return 0;

    if (a[key] === undefined) return 1;
    if (b[key] === undefined) return -1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (a[key] < b[key]) return -1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (b[key] < a[key]) return 1;
    return 0;
  };
}

export function getObjectSorter(key1: string, key2: string) {
  return (a: any, b: any) => {
    if (a[key1]?.[key2] === undefined && b[key1]?.[key2] === undefined) return 0;

    if (a[key1]?.[key2] === undefined) return 1;
    if (b[key1]?.[key2] === undefined) return -1;
    if (a[key1]?.[key2] < b[key1]?.[key2]) return -1;
    if (b[key1]?.[key2] < a[key1]?.[key2]) return 1;
    return 0;
  };
}

export function filterSources(filter: Record<string, SourceFilterCondition>, sequence: SequenceColumn): boolean {
  const labels = getSourceLabels(sequence).map((label) => label.title);

  return _.reduce(filter, (result, condition, label) => {
    if (!result) return false;
    if (_.isUndefined(condition) || condition === SourceFilterCondition.MAY_BE) return true;

    if (condition === SourceFilterCondition.MUST_BE) {
      return _.includes(labels, label);
    }

    if (condition === SourceFilterCondition.MUST_NOT_BE) {
      return !_.includes(labels, label);
    }

    return true;
  }, true);
}

export function filterData(data: SequenceData, filter: FilterCollection): SequenceData {
  return _.filter(data, (sequence) => {
    return _.reduce(filter, (result, { type, value }, column) => {
      if (!result) return false;

      if (type === FilterType.SEARCH && value) {
        if (!_.includes(sequence[column as keyof Sequence]?.toString().toLowerCase(), value?.toLowerCase())) {
          return false;
        }
      }

      if (type === FilterType.NUMBER_RANGE && value) {
        const rowValue = sequence[column as keyof Sequence];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const valueNumber = _.isNumber(rowValue) ? rowValue : rowValue?.max;

        if (valueNumber) {
          if (value.min && valueNumber < value.min) {
            return false;
          }
          if (value.max && valueNumber > value.max) {
            return false;
          }
        } else {
          return false;
        }
      }

      if (type === FilterType.SOURCE && column === 'source' && value) {
        return filterSources(value, sequence);
      }

      return true;
    }, true);
  });
}

export function getSourceLabels(sequence: Sequence): DataSource[] {
  return sequence.source.map(item => dataSources[item]);
}
