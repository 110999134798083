import _ from 'lodash';
// Components
import { TitleTooltip } from 'components/TitleTooltip';
import { NameCellRenderer, NumberCellRenderer, SourceCellRenderer, TagCellRenderer } from './cells';
// Types
import { SequenceColumns } from './types';
import { Tag, SequenceTag, SequenceColumn as PinedSequence, Similarity, StructureSimilarity } from 'types';
// Helpers
import { getDefaultSorter, getObjectSorter } from './helpers';

export const Hints = {
  similarity: <>
    <i>Similarity</i> refers to the proportion of positions in the aligned sequences that have a similar (e.g., hydrophobicity, charge, size) amino acid.<br/>
    In contrast to <i>identity</i> X is not considered similar to itself.<br/>
    Linear scale from 0 to 1.<br/>
    1: Perfect match, 0: No match.
    </>,
  thermostability: 'Thermostability prediction, values below 0.3 indicates that the protein is not thermostable, values above 0.7 indicate a thermostable protein. No statement can be made for values between 0.3 and 0.7.',
  solubility: <>
    Predicted solubility of protein when expressed in <i>E.coli</i> 0.9 highly probable to be soluble, 0.1 high probability that insoluble.
    </>,
  expression:
    <>
      Proteins predicted to be highly expressed in <i>E.coli</i> have a value approaching 0.99.
    </>,
  usability: 'usability = expression * solubility',
  length: 'Number of characters per sequence. Usually equal to the amount of amino acids in the sequence.',
  size: 'The amount of sequences inside the cluster.',
  gaps: 'Gaps refer to the proportion of positions in the alignment insertions or deletions in one of the sequences ' +
    'occured. ' +
    '0: No gaps, 1: Only gaps. (Impossible)',
  identity: <>
    <i>Identity</i> refers to the proportion of positions in the aligned sequences that have exactly the same amino acid.<br/>
    In contrast to <i>similarity</i> X is considered similar to itself.<br/>
    Linear scale from 0 to 1.<br/>
    1: Perfect match, 0: No match.
  </>,
  nearest_query_nearest_id: 'The nearest sequence ID to a query sequence. Based on sequence similarity.',
  source: '',
  nearest_query_structure_nearest_id: 'The structurally nearest sequence ID to a query sequence. Based on tm-score.',
  nearest_query_structure_tm: 'Describes the difference between two structures. 1: Perfect match, [0.5, 1): Significant match, 0.2 Expected value for randomly chosen structures.',
  nearest_query_structure_rmsd: 'Describes the difference between two structures. 0: Perfect match',
  ogt_t40: 'Probability for the optimal growth temperature to be lower than 40°C.',
  ogt_t45: 'Probability for the optimal growth temperature to be between 40°C and 45°C.',
  ogt_t50: 'Probability for the optimal growth temperature to be between 45°C and 50°C.',
  ogt_t55: 'Probability for the optimal growth temperature to be between 50°C and 55°C.',
  ogt_t60: 'Probability for the optimal growth temperature to be between 55°C and 60°C.',
  ogt_t65: 'Probability for the optimal growth temperature to be between 60°C and 65°C.'
};

export const Stats: Record<string, string> = {
  similarity: 'Similarity',
  length: 'Length',
  thermostability: 'Thermostability',
  solubility: 'Solubility',
  size: 'Cluster Size',
  source: 'Source',
  usability: 'Usability',
  expression: 'Expression',
  gaps: 'Similarity Gaps',
  identity: 'Identity',
  nearest_query_nearest_id: 'Query ID (Sequence)',
  nearest_query_structure_nearest_id: 'Query ID (Structure)',
  nearest_query_structure_tm: 'TM-Score',
  nearest_query_structure_rmsd: 'RMSD',
  ogt_t40: 'OGT t40',
  ogt_t45: 'OGT t45',
  ogt_t50: 'OGT t50',
  ogt_t55: 'OGT t55',
  ogt_t60: 'OGT t60',
  ogt_t65: 'OGT t65',
};

export const ValidColumns = [
  'id',
  'length',
  'thermostability',
  'solubility',
  'usability',
  'ogt_t40',
  'ogt_t45',
  'ogt_t50',
  'ogt_t55',
  'ogt_t60',
  'ogt_t65',
  'nearest_query_structure_tm',
  'nearest_query_structure_rmsd',
  'nearest_query_structure_nearest_id',
  'nearest_query_nearest_id'
];

export const Columns: Record<string, string> = {
  id: 'ID',
  name: 'Name',
  ...Stats,
};

export const renderPinedColumn = (item: { dataIndex: string, key: string, title: string, width: number | undefined, sorter: string | undefined }, pined: PinedSequence | undefined) => {
  if (!pined) return item;

  const parseKey = (key: string) => {
    switch (key) {
      case 'nearest_query_structure_nearest_id': {
        return 'nearest_id';
      }
      case 'nearest_query_structure_tm': {
        return 'tm';
      }
      case 'nearest_query_structure_rmsd': {
        return 'rmsd';
      }
      case 'nearest_query_nearest_id': {
        return 'nearest_id';
      }
      default: {
        return key;
      }
    }
  };

  const parseValue = (value: Similarity & StructureSimilarity, key: string) => {
    switch (key) {
      case 'gaps':
      case 'identity':
      case 'similarity': {
        return value?.[key];
      }
      case 'nearest_query_structure_nearest_id': {
        return value?.nearest_id;
      }
      case 'nearest_query_structure_tm': {
        return value?.tm;
      }
      case 'nearest_query_structure_rmsd': {
        return value?.rmsd;
      }
      case 'nearest_query_nearest_id': {
        return value?.nearest_id;
      }
      default: {
        return value;
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const value = _.isObject(pined?.[item.dataIndex]) ? pined?.[item.dataIndex]?.[parseKey(item.key)] : pined?.[item.dataIndex];

  if (item.dataIndex === 'name') {
    return {
      ...item,
      children: [{
        title: NumberCellRenderer(value),
        dataIndex: item?.dataIndex,
        render: NameCellRenderer,
        shouldCellUpdate: () => true,
        className: 'child-column',
        ellipsis: true,
      }]
    };
  }

  if (item.dataIndex === 'source') {
    return {
      ...item,
      children: [{
        title: SourceCellRenderer(null, pined),
        dataIndex: item?.dataIndex,
        render: SourceCellRenderer,
        shouldCellUpdate: () => true,
        className: 'child-column',
        ellipsis: true,
      }]
    };
  }

  return {
    ...item,
    children: [{
      title: NumberCellRenderer(value),
      dataIndex: item?.dataIndex,
      render: (value: any) => NumberCellRenderer(parseValue(value, item?.key) as string & number & { min: number, max: number }),
      className: 'child-column',
      ellipsis: true,
    }]
  };
};

export const getGenericTagColumn = (tag: Tag, labelTagsValues?: Record<string, SequenceTag[]>) => {
  return {
    title: tag.title,
    dataIndex: tag.uuid,
    key: tag.title,
    // Custom render for Label Tags
    render: (name: string, seq: any, index: number) => TagCellRenderer(name, seq, index, tag, labelTagsValues),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    sorter: getDefaultSorter(tag.uuid),
    ellipsis: { showTitle: true },
  };
};

export const getColumns: SequenceColumns = [{
  title: 'Id',
  dataIndex: 'id',
  key: 'id',
  sorter: getDefaultSorter('id'),
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
}, {
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  sorter: getDefaultSorter('name'),
  render: NameCellRenderer,
  shouldCellUpdate: () => true,
  ellipsis: { showTitle: true },
}, {
  title: () => {
    const hint = Hints.similarity;

    return <TitleTooltip title='Similarity' hint={hint}/>;
  },
  dataIndex: 'nearest_query',
  key: 'similarity',
  render: value => NumberCellRenderer(value?.similarity),
  sorter: getObjectSorter('nearest_query', 'similarity'),
  sortDirections: ['ascend', 'descend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  // width: 100
}, {
  title: () => {
    if (Hints.length) {
      const hint = Hints.length;

      return <TitleTooltip title='Length' hint={hint}/>;
    } else return 'Length';
  },
  dataIndex: 'length',
  key: 'length',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('length'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
}, {
  title: () => {
    const hint = Hints.thermostability;

    return <TitleTooltip title='Thermostability' hint={hint}/>;
  },
  dataIndex: 'thermostability',
  key: 'thermostability',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('thermostability'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  // width: 120,
}, {
  title: () => {
    const hint = Hints.solubility;

    return <TitleTooltip title='Solubility' hint={hint}/>;
  },
  dataIndex: 'solubility',
  key: 'solubility',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('solubility'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  // width: 120
}, {
  title: () => {
    if (Hints.size) {
      const hint = Hints.size;

      return <TitleTooltip title='Cluster Size' hint={hint}/>;
    } else return 'Cluster Size';
  },
  dataIndex: 'size',
  key: 'size',
  sorter: getDefaultSorter('size'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  // width: 70
}, {
  title: () => {
    if (Hints.usability) {
    const hint = Hints.usability;

    return <TitleTooltip title='Usability' hint={hint}/>;
    } else return 'Usability';
  },
  dataIndex: 'usability',
  key: 'usability',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('usability'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 120
}, {
  title: () => {
    if (Hints.gaps) {
      const hint = Hints.gaps;

      return <TitleTooltip title='Similarity Gaps' hint={hint}/>;
    } else return 'Similarity Gaps';
  },
  dataIndex: 'nearest_query',
  key: 'gaps',
  render: value => NumberCellRenderer(value?.gaps),
  sorter: getObjectSorter('nearest_query', 'gaps'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 120
}, {
  title: () => {
    if (Hints.identity) {
      const hint = Hints.identity;

      return <TitleTooltip title='Identity' hint={hint}/>;
    } else return 'Identity';
  },
  dataIndex: 'nearest_query',
  key: 'identity',
  render: value => NumberCellRenderer(value?.identity),
  sorter: getObjectSorter('nearest_query', 'identity'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 120
}, {
  title: () => {
    if (Hints.nearest_query_nearest_id) {
      const hint = Hints.nearest_query_nearest_id;

      return <TitleTooltip title='Query ID' hint={hint}/>;
    } else return 'Query ID';
  },
  dataIndex: 'nearest_query',
  key: 'nearest_query_nearest_id',
  render: value => NumberCellRenderer(value?.nearest_id),
  sorter: getObjectSorter('nearest_query', 'nearest_id'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 100
}, {
  title: () => {
    if (Hints.nearest_query_structure_nearest_id) {
      const hint = Hints.nearest_query_structure_nearest_id;

      return <TitleTooltip title='Near structure ID' hint={hint}/>;
    } else return 'Near structure ID';
  },
  dataIndex: 'nearest_structure_query',
  key: 'nearest_query_structure_nearest_id',
  render: value => NumberCellRenderer(value?.nearest_id),
  sorter: getObjectSorter('nearest_structure_query', 'nearest_id'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 100
}, {
  title: () => {
    if (Hints.nearest_query_structure_tm) {
      const hint = Hints.nearest_query_structure_tm;

      return <TitleTooltip title='TM-Score' hint={hint}/>;
    } else return 'TM-Score';
  },
  dataIndex: 'nearest_structure_query',
  key: 'nearest_query_structure_tm',
  render: value => NumberCellRenderer(value?.tm),
  sorter: getObjectSorter('nearest_structure_query', 'tm'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 100
}, {
  title: () => {
    if (Hints.nearest_query_structure_rmsd) {
      const hint = Hints.nearest_query_structure_rmsd;

      return <TitleTooltip title='RMSD' hint={hint}/>;
    } else return 'RMSD';
  },
  dataIndex: 'nearest_structure_query',
  key: 'nearest_query_structure_rmsd',
  render: value => NumberCellRenderer(value?.rmsd),
  sorter: getObjectSorter('nearest_structure_query', 'rmsd'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 100
}, {
  title: () => {
    if (Hints.expression) {
      const hint = Hints.expression;

      return <TitleTooltip title='Expression' hint={hint}/>;
    } else return 'Expression';
  },
  dataIndex: 'expression',
  key: 'expression',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('expression'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 100
}, {
  title: () => {
    if (Hints.source) {
      const hint = Hints.source;

      return <TitleTooltip title='Source' hint={hint}/>;
    } else return 'Source';
  },
  dataIndex: 'source',
  key: 'source',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  render: SourceCellRenderer,
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 200,
}, {
  title: () => {
    if (Hints.ogt_t40) {
      const hint = Hints.ogt_t40;

      return <TitleTooltip title='OGT t40' hint={hint}/>;
    } else return 'OGT t40';
  },
  dataIndex: 'ogt_t40',
  key: 'ogt_t40',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('ogt_t40'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 110
}, {
  title: () => {
    if (Hints.ogt_t45) {
      const hint = Hints.ogt_t45;

      return <TitleTooltip title='OGT t45' hint={hint}/>;
    } else return 'OGT t45';
  },
  dataIndex: 'ogt_t45',
  key: 'ogt_t45',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('ogt_t45'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 110
}, {
  title: () => {
    if (Hints.ogt_t50) {
      const hint = Hints.ogt_t50;

      return <TitleTooltip title='OGT t50' hint={hint}/>;
    } else return 'OGT t50';
  },
  dataIndex: 'ogt_t50',
  key: 'ogt_t50',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('ogt_t50'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 110
}, {
  title: () => {
    if (Hints.ogt_t55) {
      const hint = Hints.ogt_t55;

      return <TitleTooltip title='OGT t55' hint={hint}/>;
    } else return 'OGT t55';
  },
  dataIndex: 'ogt_t55',
  key: 'ogt_t55',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('ogt_t55'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 110
}, {
  title: () => {
    if (Hints.ogt_t60) {
      const hint = Hints.ogt_t60;

      return <TitleTooltip title='OGT t60' hint={hint}/>;
    } else return 'OGT t60';
  },
  dataIndex: 'ogt_t60',
  key: 'ogt_t60',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('ogt_t60'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 110
}, {
  title: () => {
    if (Hints.ogt_t65) {
      const hint = Hints.ogt_t65;

      return <TitleTooltip title='OGT t65' hint={hint}/>;
    } else return 'OGT t65';
  },
  dataIndex: 'ogt_t65',
  key: 'ogt_t65',
  render: NumberCellRenderer,
  sorter: getDefaultSorter('ogt_t65'),
  sortDirections: ['descend', 'ascend'],
  ellipsis: { showTitle: true },
  shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  width: 110
}];
