import { useEffect, useRef } from 'react';
import { StructureItem } from 'containers/types';
import { useLocation, Link } from 'react-router-dom';
import { Button, Typography, List, Checkbox } from 'antd';
import { RadarChartOutlined } from '@ant-design/icons';
import stylesheet from './OptimisationTab.module.scss';
import './OptimisationTab.scss';
import { createPluginUI } from 'molstar/lib/mol-plugin-ui/react18';
import { sequenceApi } from 'api';

type Props = {
    key: string;
    item: StructureItem;
    structureId: string;
    setStructureId: (id: string) => void;
  };

export function RenderedStructureItem({ item, structureId, setStructureId }: Props) {
    const { id, source_title, link, external_id, ngl_link } = item;
    const { pathname } = useLocation();
    const ref = useRef<HTMLDivElement>(null);

    const href = external_id
      ? `${pathname}/structure?structure_id=${id}&url=${ngl_link}`
      : `${pathname}/structure?structure_id=${id}`;

    useEffect(() => {
        let pluginContext: any;
        const renderMolStar = async () => {
            if (ref.current === null) {
                console.log('Ref is null');
            }
            pluginContext = ref.current ? await createPluginUI(ref.current) : null;
            if (!pluginContext) {
                console.log('Plugin context is not created');
                return;
            }
            const isLink = external_id !== undefined;
            if (isLink) {
              const items = await pluginContext.builders.data.download(
                { url: ngl_link },
                { state: { isGhost: true } });

                const trajectory = await pluginContext.builders.structure.parseTrajectory(items, 'pdb');
                await pluginContext.builders.structure.hierarchy.applyPreset(trajectory, 'default');
            } else {
              sequenceApi.getStructurePdb({ structureId: id })
              .then(async (structureData) => {
                  const items = await pluginContext.builders.data.rawData({ data: structureData, label: 'test' });
                  const trajectory = await pluginContext.builders.structure.parseTrajectory(items, 'pdb');
                  await pluginContext.builders.structure.hierarchy.applyPreset(trajectory, 'default');
                })
              .catch((error) => {
                console.error('Get sequence group info data error:', error);
              });
          }
        };
        void renderMolStar();

        return () => {
            pluginContext?.dispose();
        };
    }, [ref.current, external_id, id, ngl_link]);

    return (
      <List.Item key={id}>
        <Checkbox checked={structureId === id} disabled={structureId !== '' && structureId !== id} onChange={(e) => e.target.checked ? setStructureId(id) : setStructureId('')}></Checkbox>
        <Typography.Text style={{ width: '200px' }} ellipsis>{source_title}: {link}</Typography.Text>

      <Link
      to={href}
      >
        <Button
        style={{ width: '200px' }}
          type="default"
          icon={<RadarChartOutlined />}
        >
          <span>View Full Screen</span>
        </Button>
      </Link>

        <div ref={ref} className={stylesheet.optimisationContainer}>

        </div>

      </List.Item>
    );
}
