import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import Phylocanvas from '@phylocanvas/phylocanvas.gl';
import { Dropdown, Empty, Space, Spin, Typography } from 'antd';
import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { SequenceData, BondStyle } from 'types';
import stylesheet from './SequenceTree.module.scss';
import colors from './colorScale.json';
import colorOptions from './colorOptions';
import { FilterCollection } from 'components/SequenceTable/types';
import { filterData } from 'components/SequenceTable/helpers';

type Props = {
  treeRef?: any;
  loading: boolean;
  error: boolean;
  source?: string;
  sequenceData?: SequenceData;
  filter: FilterCollection;
};

export function SequenceTree({
  treeRef = null,
  loading,
  error,
  source,
  sequenceData,
  filter,
}: Props) {
  const container = useRef<HTMLDivElement>(null);
  const canvas = useRef<HTMLDivElement>(null);

  const [size, setSize] = useState({ width: 0, height: 0 });
  const [colorMode, setColorMode] = useState(false);
  const [selected, setSelected] = useState('0');

  const handleSetTreeSize = () => {
    const rect = container.current?.getBoundingClientRect();

    setSize({
      width: rect?.width ?? 0,
      height: rect?.height ?? 0,
    });
  };

  // List of sequences filtered by table filters
  const getFilteredData = useMemo(() => {
    if (_.isEmpty(filter)) {
      return sequenceData;
    } else if (sequenceData) {
      return filterData(sequenceData, filter);
    }
  }, [filter, sequenceData]);

  const getSelectedSequences = () => {
    const checkedBoxes = document.querySelectorAll('input[name=mycheckboxes]:checked');
    const checkedList: string[] = [];
    checkedBoxes.forEach(item => {
      const id = item.getAttribute('data-id');
      if (id) {
        checkedList.push(id);
      };
    });

    return checkedList;
  };

  const getStyles = (colorMode?: boolean, key?: string) => {
    const checkedList = getSelectedSequences();
    const styles: Record<string, BondStyle> = {};
    sequenceData?.forEach((item: any) => {
      const { id, similarity } = item;
      if (checkedList.includes(id)) {
        styles[id] = {
          shape: Phylocanvas.Shapes.Cross
        };
      }
      if (similarity === 1 && !checkedList.includes(id)) {
        styles[id] = {
          shape: Phylocanvas.Shapes.Pentagram
        };
      }
      if (colorMode) {
        // Check if related sequence is not filtered out, if yes - not color
        if (getFilteredData?.find(({ id }) => id === item.id)) {
          const identifier = colorOptions.find(val => val.key === key)?.identifier;

          const index = identifier && item[identifier] ? Math.floor(item[identifier] * 100) : 0;
          styles[id] = {
            ...styles[id],
            fillColour: colors[index]
          };
        }
      }
    });

    return styles;
  };

  const checkedHanlder = (key: string) => {
    const checked = key !== '0';
    setColorMode(checked);
    const styles = getStyles(checked, key);
    treeRef.current?.setProps({ styles });
  };

  useEffect(() => {
    const styles = getStyles(false);

    if (source && !loading && !error) {
      treeRef.current = new Phylocanvas(canvas.current, {
        styles,
        source,
        showLabels: true,
        interactive: true,
        zoom: 0,
      });

      treeRef.current.setProps({ size });
    }

    return () => {
      try {
        treeRef.current?.destroy();
      } catch (error) {
      }
    };
  }, [source, loading, error]);

  useEffect(() => {
    treeRef.current?.setProps({ size });
  }, [size]);

  const getItems = useMemo(() => {
    // Filter out options that have no data
    return colorOptions.filter(item => {
      return item.label === 'All' || sequenceData?.some((it: any) => it[item.identifier]);
    });
  }, [sequenceData]);

  // Trigger color re-render if table was filtered
  useEffect(() => {
    if (colorMode) {
      const styles = getStyles(colorMode, selected);
      treeRef.current?.setProps({ styles });
    }
  }, [filter]);

  useEffect(() => {
    window.addEventListener('resize', handleSetTreeSize);

    return () => {
      window.removeEventListener('resize', handleSetTreeSize);
    };
  }, []);

  useLayoutEffect(() => {
    handleSetTreeSize();
  }, [container.current]);

  const renderCanvas = () => {
    if (loading) {
      return (
        <div className={stylesheet.fallbackContainer}>
          <Spin indicator={(
            <LoadingOutlined style={{ fontSize: 36 }} spin />
          )} />
        </div>
      );
    }

    if (error) {
      return (
        <div className={stylesheet.fallbackContainer}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={(
              <span>Some error occurred</span>
            )}
          />
        </div>
      );
    }

    if (!source) {
      return (
        <div className={stylesheet.fallbackContainer}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      );
    }

    return (<div ref={canvas} />);
  };

  return (
    <div className={stylesheet.root}>
      <div style={{ textAlign: 'right' }}>
        <Dropdown
          placement="bottomRight"
          menu={{
            items: getItems,
            selectable: true,
            // Default is "All"
            defaultSelectedKeys: ['0'],
            onSelect: (value) => {
              setSelected(value.key);
              checkedHanlder(value.key);
            }
          }}
        >
          <Typography.Link style={{ alignItems: 'flex-end' }}>
            <Space
              className={stylesheet.dropdownSpace}
              style={selected === '0' ? { color: 'rgba(0, 0, 0, 0.88)' } : {}}
            >
              {colorOptions.find(item => item.key === selected)?.label}
              <CaretDownOutlined />
            </Space>
          </Typography.Link>
        </Dropdown>
      </div>

      <div
        id='sequenceTree'
        ref={container}
        className={stylesheet.tree}
      >
        {renderCanvas()}
      </div>
    </div>
  );
}
