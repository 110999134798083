export const ColumnListOrder = [
  { key: 'id', value: 'ID' },
  { key: 'name', value: 'Name' },
  { key: 'length', value: 'Length' },
  { key: 'source', value: 'Source' },
  {
    title: 'Sequence Similarity',
    subList: [
        { key: 'similarity', value: 'Similarity' }, { key: 'gaps', value: 'Gaps' }, { key: 'identity', value: 'Identity' }, { key: 'nearest_query_nearest_id', value: 'Query ID' }
    ]
  },
  { key: 'solubility', value: 'Solubility' },
  { key: 'usability', value: 'Usability' },
  { key: 'expression', value: 'Expression' },
  {
    title: 'Structure Similarity',
    subList: [
      { key: 'nearest_query_structure_tm', value: 'TM-Score' }, { key: 'nearest_query_structure_rmsd', value: 'RMSD' }, { key: 'nearest_query_structure_nearest_id', value: 'Query ID' },
    ]
  },
  { key: 'thermostability', value: 'Thermostability' },
  {
    title: 'OGT',
    subList: [
      { key: 'ogt_t40', value: 't40' }, { key: 'ogt_t45', value: 't45' }, { key: 'ogt_t50', value: 't50' }, { key: 'ogt_t55', value: 't55' }, { key: 'ogt_t60', value: 't60' }, { key: 'ogt_t65', value: 't65' }
    ]
  }
];
