import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Checkbox, Empty, Modal, Spin, Table } from 'antd';
import _ from 'lodash';
import Phylocanvas from '@phylocanvas/phylocanvas.gl';
// Components
import { getPreparedColumns } from 'components/SequenceTable/columns';
import { getFooter } from 'components/SequenceTable/footer/Footer';
// Partials
import { Preferences } from './preferences/Preferences';
import { SequenceInfo } from 'components/SequenceInfo';
// Static data
import { ValidColumns } from './columnsData';
// API
import { sequenceApi } from 'api';
// Icons
import { CloseCircleOutlined, LoadingOutlined, PushpinOutlined, SettingOutlined } from '@ant-design/icons';
// Types
import { ColumnFilter, FilterCollection, SequenceColumns } from 'components/SequenceTable/types';
import { SequenceData, Tag, SequenceTag, Config, SequenceColumn } from 'types';
// Styles
import stylesheet from './SequenceTable.module.scss';
import './SequenceTable.scss';

const DEFAULT_SCROLL_HEIGHT = 600;
const HEADER_HEIGHT = 55;
const FOOTER_HEIGHT = 90;
const INFO_BLOCK = 385;

interface Props {
  treeRef?: React.RefObject<any> | null;
  projectId: string;
  loading: boolean;
  error: boolean;
  data: SequenceData;
  columnsList?: Record<string, boolean>;
  sequenceGroup: string;
  groupId: number;
  handleNameEdition: (value: string, seq_id: string) => void;
  handleFilterRequest: (data: Record<string, any>, sort: string) => void;
  handleSortRequest?: (a: string) => void;
  filter: FilterCollection;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, ColumnFilter>>>;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  sort: string;
  fetchClusterData?: (id: string, replace: boolean) => void;
  containerRef?: React.RefObject<HTMLDivElement> | null;
  type: string;
  projectTags?: Tag[];
  labelTagsValues?: Record<string, SequenceTag[]>;
  frontendConfig: Config['frontendConfig'];
  updateColumnsList: (list: Record<string, boolean>) => void;
  checkedRow: string[];
  setCheckedRow: React.Dispatch<React.SetStateAction<string[]>>;
}

export function SequenceTable({
  treeRef = null,
  projectId,
  loading,
  error,
  data,
  columnsList = {},
  sequenceGroup,
  groupId,
  handleNameEdition,
  handleFilterRequest,
  handleSortRequest,
  filter,
  setFilter,
  setSort,
  sort,
  fetchClusterData,
  containerRef = null,
  type,
  projectTags = [],
  labelTagsValues,
  frontendConfig,
  updateColumnsList,
  checkedRow,
  setCheckedRow
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get('selectedSequence');

  const [scrollSize, setScrollSize] = useState<{ x: number | true, y: number }>({ x: 0, y: DEFAULT_SCROLL_HEIGHT });
  const [columns, setColumns] = useState<SequenceColumns>([]);
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [columnsAvailability, setColumnsAvailability] = useState(columnsList);
  const [tagColumns, setTagColumns] = useState<Record<string, boolean>>({});
  const [selectedRow, setSelectedRow] = useState<string>(id ?? '');
  const [pinedSeq, setPinedSeq] = useState<SequenceColumn>();

  const pinSequence = () => {
    const selectedSeq = data.find(item => item.id === selectedRow);
    if (pinedSeq === selectedSeq) {
      setPinedSeq(undefined);
    } else {
      setPinedSeq(selectedSeq);
    }
  };

  const updateColumns = (list: Record<string, boolean>) => {
    const config = type === 'sequence'
      ? { userConfig: { columns: list } }
      : { userConfig: { columnsCluster: list } };

    sequenceApi.setProjectConfig({ projectId, config })
      .then(() => {
        updateColumnsList(list);
      })
      .catch(() => {});
  };

  useEffect(() => {
    handleWindowResize();
    // Update scroll area on sequence pin action
  }, [containerRef, pinedSeq]);

  useEffect(() => {
    const tagColumnsList: Record<string, boolean> = {};
    projectTags.forEach(it => {
      // All tag calumns are hidden by default
      tagColumnsList[it.title] = false;
    });
    // Combine list of default columns and tags list
    setTagColumns(tagColumnsList);
    setColumnsAvailability(columnsList);
  }, [columnsList, projectTags]);

  const showModal = () => {
    setShowPreferencesModal(!showPreferencesModal);
  };

  const showControlButtons = (seq_id: string, checked: boolean, similarity: boolean) => {
    const button = document.getElementById('compareButton');
    const searchButton = document.getElementById('searchButtonControl');
    const checkedBoxes = document.querySelectorAll('input[name=mycheckboxes]:checked');
    const exportButton = document.getElementById('exportButtonControl');
    if (checkedBoxes.length) {
      if (searchButton) searchButton.style.display = 'block';
    } else {
      if (searchButton) searchButton.style.display = 'none';
    }
    if (checkedBoxes.length && checkedBoxes.length < 4) {
      if (button) button.style.display = 'block';
    } else {
      if (button) button.style.display = 'none';
    }
    if (checkedBoxes.length && checkedBoxes.length <= 10) {
      if (exportButton) exportButton.style.display = 'block';
    } else {
      if (exportButton) exportButton.style.display = 'none';
    }

    const styles = treeRef?.current?.props.styles;
    if (checked) {
      treeRef?.current?.setProps({
        styles: {
          ...styles,
          [seq_id]: {
            ...styles[seq_id],
            shape: Phylocanvas.Shapes.Cross
          }
        }
      });
    } else {
      treeRef?.current?.setProps({
        styles: {
          ...styles,
          [seq_id]: {
            ...styles[seq_id],
            shape: similarity ? Phylocanvas.Shapes.Pentagram : Phylocanvas.Shapes.Circle
          }
        }
      });
    }
  };

  const handleSelectRow = (checked: boolean, record: SequenceColumn) => {
    showControlButtons(record.id, checked, record?.nearest_query?.similarity === 1);

    if (checked) {
      setCheckedRow(state => {
        return [
          ...state,
          record.id
        ];
      });
    } else {
      setCheckedRow(state => {
        return state.filter(item => item !== record.id);
      });
    }
  };
  const selection = {
    columnTitle: (
      <div className={stylesheet.columnSelection}>
        <SettingOutlined style={{ cursor: 'pointer' }} onClick={showModal} />
        {pinedSeq && <PushpinOutlined style={{ cursor: 'pointer' }} />}
      </div>
    ),
    columnWidth: 50,
    renderCell: (checked: boolean, record: SequenceColumn) => {
      const disabled = !!record.cluster_id;

      return (
        <Checkbox
          disabled={disabled}
          type='checkbox'
          name='mycheckboxes'
          checked={checkedRow.includes(record.id)}
          onClick={e => {
            e.stopPropagation();
            const node = e.target as HTMLInputElement;
            handleSelectRow(node.checked, record);
          }}
          data-id={record.id} />
      );
    },
  };

  const handleSorting = (sort: string) => {
    if (handleSortRequest) {
      handleSortRequest(sort);
    }
  };

  const handleFilter = (column: string, columnFilter: ColumnFilter | undefined) => {
    if (column !== 'seq_name') {
      const combineFilters = columnFilter
      ? {
        ...filter,
        [column]: columnFilter,
      }
      : _.omit(filter, column);

      handleFilterRequest(combineFilters, sort);
    };

    if (columnFilter) {
      setFilter((filter: FilterCollection) => ({
        ...filter,
        [column]: columnFilter,
      }));
    } else {
      setFilter(_.omit(filter, column));
    }
  };

  const handleFilterReset = (column: string) => {
    setFilter({});
    if ((column === 'seq_name' && Object.keys(filter).length > 1)) {
      handleFilterRequest({}, sort);
    }
  };

  const handleWindowResize = () => {
    // Calculate hight in regards to bottom details block
    const cont = containerRef?.current?.getBoundingClientRect();
    const scrollX = cont?.width ?? 0;
    const scrollY = cont ? cont?.height - HEADER_HEIGHT - FOOTER_HEIGHT : 500;
    // Figure out how many columns is available in pin mode
    const trueCount = _.values(columnsAvailability).filter(Boolean).length;

    setScrollSize({ x: pinedSeq ? trueCount * 120 : scrollX, y: selectedRow ? scrollY - INFO_BLOCK : scrollY });
  };

  const handleTreeClick = () => {
    if (!treeRef?.current) return;

    const { highlightedId } = treeRef.current.props;
    const selectedId = highlightedId?.toLowerCase().replace('s', '');

    if (selectedId) {
      setSelectedRow(selectedId);
      updateURLSearchParams(selectedId);
      scrollToSelectedRow(selectedId);
    }
  };

  const closeSequenceInfo = () => {
    clearURLSearchParams();
    setSelectedRow('');
  };

  const scrollToSelectedRow = (selectedId: string) => {
    const rowIndex = data.findIndex(item => item.id === selectedId);

    const tableBody = document.getElementsByClassName('rc-virtual-list-holder')[0];
    const rowHeight = 62.5;
    tableBody?.scrollTo(0, rowIndex * rowHeight);
  };

  const onRowClick = (record: SequenceColumn) => {
    if (record.cluster_id) {
      fetchClusterData?.(record.cluster_id, false);
    } else {
      setSelectedRow(record.id);
      updateURLSearchParams(record.id);
    }
  };

  const updateURLSearchParams = (id: string) => {
    // Modify an existing query parameter
    const newParams = new URLSearchParams();
    newParams.set('selectedSequence', id);

    navigate(`?${newParams.toString()}`);
  };

  const clearURLSearchParams = () => {
    navigate(window.location.pathname);
  };

  useEffect(() => {
    const preparedColumns = getPreparedColumns({
      filter,
      onFilter: handleFilter,
      onFilterReset: handleFilterReset,
      allData: data,
      handleNameEdition,
      columnsAvailability,
      projectTags: frontendConfig?.tags.enable === false ? [] : projectTags,
      groupId,
      frontendConfig,
      labelTagsValues,
      tagColumnsList: tagColumns,
      pinedSeq,
    });

    setColumns(preparedColumns);
  }, [filter, data, columnsAvailability, pinedSeq, sort]);

  useEffect(() => {
    const tree = document.getElementById('sequenceTree');

    window.addEventListener('resize', () => handleWindowResize());
    tree?.addEventListener('click', handleTreeClick);

    return () => {
      window.removeEventListener('resize', () => handleWindowResize());
      tree?.removeEventListener('click', handleTreeClick);
    };
  }, [data]);

  useEffect(() => {
    handleWindowResize();
  }, [selectedRow]);

  if (error) {
    return (
      <div className={stylesheet.fallbackContainer}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={(
            <span>Some error occurred</span>
          )}
        />
      </div>
    );
  }

  return (
    <div className={stylesheet.container}>
      <Table
        virtual
        // It prevents overlapping of icon tooltip
        locale={{
          triggerDesc: '',
          triggerAsc: '',
          cancelSort: ''
        }}
        id="sequence-table"
        rowKey="id"
        loading={{
          indicator: <Spin indicator={(
            <LoadingOutlined style={{ fontSize: 36 }} spin />
          )} />,
          spinning: loading,
          tip: 'Loading this page can take several minutes',
          wrapperClassName: stylesheet.loadingText
        }}
        dataSource={data}
        columns={columns}
        scroll={scrollSize}
        pagination={false}
        onRow={record => {
          return {
            onClick: () => onRowClick(record)
          };
        }}
        rowSelection={selection}
        footer={
          getFooter({
            seqsList: data,
            groupId,
            sequenceGroup,
            selectedSequences: checkedRow,
            total: data.length,
            projectId
          })
        }
        onChange={(pagination, filters, sorter: any) => {
          const { order, columnKey } = sorter;
          const sortDirections = order === 'ascend' ? 'asc' : 'desc';
          const sort = order ? `${columnKey as string}:${sortDirections}` : '';

          // Check if server sorting available for this column
          if (ValidColumns.includes(columnKey)) {
            setSort(sort);
            handleSorting(sort);
          } else {
            setSort('');
          }
        }}
        rowClassName={(record) => {
          return `${record.id} ${record.id === selectedRow ? 'highlight' : ''}`;
        }}
      />

      {!!selectedRow && !!data.length &&
        <div className={stylesheet.tabsContainer}>
          <div className={stylesheet.constols}>
            <PushpinOutlined className={stylesheet.closeIcon} onClick={pinSequence} style={pinedSeq?.id === selectedRow ? { color: '#1677ff' } : {}} />
            <CloseCircleOutlined onClick={closeSequenceInfo} className={stylesheet.closeIcon} rev={undefined} />
          </div>
          <SequenceInfo
            handleNameEdition={handleNameEdition}
            projectId={projectId}
            groupId={groupId}
            // fetchClusterData={fetchClusterData}
            frontendConfig={frontendConfig}
            selectedRow={selectedRow}
          />
        </div>
      }

      <Modal
        title="Column Visibility"
        okText="Save"
        cancelText="Cancel"
        onCancel={showModal}
        open={showPreferencesModal}
        closable={false}
        footer={null}
      >
        <Preferences
          onOk={showModal}
          onCancel={showModal}
          columns={columnsAvailability}
          frontendConfig={frontendConfig}
          tagColumns={frontendConfig?.tags.enable === false ? {} : tagColumns}
          updateColumns={updateColumns}
        />
      </Modal>
    </div>
  );
}
