import { axios } from './index';

import { LoginPayload, UpdatePasswordPayload, User } from '../types';

const LOGIN_URL = '/auth/login';
const LOGOUT_URL = '/auth/logout';
const UPDATE_PASSWORD_URL = '/auth/updatePassword';
export async function login(payload: LoginPayload) {
  const result = await axios.post(LOGIN_URL, payload);

  return result.data as User;
}

export async function logout() {
  await axios.post(LOGOUT_URL);
}

export async function updatePassword(payload: UpdatePasswordPayload) {
  await axios.post(UPDATE_PASSWORD_URL, payload);
}
