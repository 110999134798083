import React, { useState } from 'react';
import { Button, InputNumber, Space } from 'antd';
import _ from 'lodash';

import { FilterDropdownProps } from 'antd/es/table/interface';
import { FilterProps, FilterType } from 'components/SequenceTable/types';

import stylesheet from './NumberRangeFilter.module.scss';

type Props = FilterProps & FilterDropdownProps;

export function NumberRangeFilter(props: Props): JSX.Element {
  const [minValue, setMinValue] = useState<number | undefined>(props.value?.min ?? undefined);
  const [maxValue, setMaxValue] = useState<number | undefined>(props.value?.max ?? undefined);

  const handleChangeMinValue = (value: number | null) => {
    setMinValue(value === null ? undefined : value);
  };

  const handleChangeMaxValue = (value: number | null) => {
    setMaxValue(value === null ? undefined : value);
  };

  const handleApply = (closeDropdown = false) => {
    if (_.isNumber(minValue) || _.isNumber(maxValue)) {
      props.onFilter(props.column, {
        type: FilterType.NUMBER_RANGE,
        value: { min: minValue, max: maxValue },
      });
    } else {
      props.onFilter(props.column, undefined);
    }

    props.confirm({ closeDropdown });
  };

  const handleClear = () => {
    setMinValue(undefined);
    setMaxValue(undefined);
    props.onFilter(props.column, undefined);
  };

  const handleReset = () => {
    setMinValue(undefined);
    setMaxValue(undefined);
    props.onFilterReset(props.column);
    props.confirm();
  };

  return (
    <div className={stylesheet.root}>
      <div className={stylesheet.inputContainer}>
        <InputNumber
          className={stylesheet.input}
          type="number"
          addonBefore="min"
          min={props.bounds?.min ?? 0}
          max={props.bounds?.max ?? 99999}
          value={minValue}
          onChange={handleChangeMinValue}
        />
        <InputNumber
          className={stylesheet.input}
          type="number"
          addonBefore="max"
          min={props.bounds?.min ?? 0}
          max={props.bounds?.max ?? 99999}
          value={maxValue}
          onChange={handleChangeMaxValue}
        />
      </div>
      <Space>
        <Button
          size="small"
          className={stylesheet.button}
          onClick={() => handleReset()}
        >
          Reset
        </Button>
        <Button
          size="small"
          className={stylesheet.button}
          onClick={() => handleClear()}
        >
          Clear
        </Button>
        <Button
          size="small"
          type="primary"
          className={stylesheet.button}
          onClick={() => handleApply(true)}
        >
          Apply
        </Button>
      </Space>
    </div>
  );
}

export function getNumberRangeFilter(props: FilterProps) {
  return function NumberRangeFilterWrapper(dropdownProps: FilterDropdownProps): JSX.Element {
    return (<NumberRangeFilter {...props} {...dropdownProps} />);
  };
}
