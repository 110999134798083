import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import stylesheet from './Loader.module.scss';

export function Loader() {
  return (
    <div className={stylesheet.root}>
      <Spin indicator={(
        <LoadingOutlined style={{ fontSize: 48 }} spin />
      )} />
    </div>
  );
}
