import { useCallback, useState } from 'react';
import { AutoComplete, Divider, Input, Select } from 'antd';
import { EnterOutlined } from '@ant-design/icons';
// API
import { sequenceApi } from 'api';
// Components
import { TagCreate } from './TagCreate';
import { EditProbability } from './components/EditProbability';
// Helpers
import { isGenericNumber, isNumberInRange, roundToNearestRenderableValue } from 'helpers/tagFieldsValidation';
// Types
import { Tag, SequenceTag } from 'types';
// Styles
import stylesheet from './TagTab.module.scss';

type Props = {
  projectId: string;
  tags: Tag[];
  sequenceId?: string;
  fetchProjectTags: () => void;
  addTagToList: (tag: any) => void;
};

export function TagAttachForm({ projectId, tags, sequenceId, fetchProjectTags, addTagToList }: Props) {
  const [title, setTitle] = useState('');
  const [tagTitle, setTagTitle] = useState('');
  const [type, setType] = useState('');
  const [tagId, setTagId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [tagLabels, setTagLabels] = useState<SequenceTag[]>([]);
  const [labelValue, setLabelValue] = useState('');
  const [open, setOpen] = useState(false);

  const pressEnter = (e: any) => {
    const value = e.target.value;

    if (error) return false;
    if (value && type !== 'label') {
      // Save value for attached tag if it's type is not a label
      const parsedVal = parseFloat(e.target.value);
      saveSequenceTagValue(parsedVal);
    } else if (value) {
      // Look for already exist label
      const preset = tagLabels.find(item => item.title === value);
      if (preset?.id) {
        saveSequenceTagValue(preset.id);
      } else {
        createNewLabel(value);
      }
    }
  };

  const createNewLabel = (value: string) => {
    projectId && sequenceId && sequenceApi.setTagLabel({ projectId, tagId, title: value })
      .then(data => {
        if (data[0]) {
          setTagLabels((state: any) => {
            return [
              ...state,
              ...data,
            ];
          });
          saveSequenceTagValue(data[0].id);
        }
      })
      .catch(e => {
        setErrorMessage(e.message);
      });
  };

  const saveSequenceTagValue = (newValue: number) => {
    projectId && sequenceId && sequenceApi.setSequenceTagValue({ projectId, tagId, sequenceId, labelId: newValue })
    .then(() => {
      // New tag
      const tag = { uuid: tagId, tag_id: tagId, title: tagTitle, label_id: newValue, type };
      addTagToList(tag);
      setTitle('');
      setTagId('');
      setLabelValue('');
      setType('');
    })
    .catch(e => {
      setErrorMessage(e.message);
    });
  };

  const handleValidationMethod = (value: string, type?: string) => {
    switch (type) {
      case 'number': {
        const parsed = parseFloat(value);
        if (!isGenericNumber(parsed)) {
          return 'Number could be negative or a float. Must not be +inf, -inf or NaN';
        } else {
          return '';
        }
      }
      case 'probability': {
        const parsed = parseFloat(value);
        if (!isNumberInRange(parsed)) {
          return 'Number must be in range [0, 1]';
        } else {
          return '';
        }
      }
      default:
        return '';
    }
  };

  const handleValidation = (value: any, type: string) => {
    const validation = handleValidationMethod(value, type);

    if (validation) {
      setErrorMessage(validation);
      setError(true);
    } else {
      setErrorMessage('');
      setError(false);
    }
  };

  const renderValue = (value: number) => {
    if (value === -1) {
      return '-1';
    } else if (value === 0) {
      return '0';
    } else if (value === 1) {
      return '1';
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;

    let newVal = val;
    if (type === 'scoring') {
      // Prevent from rounding if user typed only "-" or n. only.
      if (newVal && newVal !== '-' && (newVal[1] !== '.' || newVal.length > 2)) {
        const roundedValue = roundToNearestRenderableValue(newVal);
        newVal = renderValue(roundedValue) as unknown as string;
      }
      setTitle(newVal);
    } else {
      setTitle(newVal);
    }

    if (newVal) {
      handleValidation(newVal, type);
    } else {
      setErrorMessage('');
      setError(false);
    }
  };

  const handleSelectTag = (value: string, type: string, title: string) => {
    setTagId(value);
    setType(type);
    setTagTitle(title);

    if (type === 'label') {
      projectId && sequenceId && sequenceApi.getTagLabels({ projectId, tagId: value })
        .then(data => {
          setTagLabels(data);
        })
        .catch(e => {
          setErrorMessage(e.message);
        });
    }
  };

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const renderTagsOption = (list: Tag[]) => {
    return list.map(item => {
      return (
        <Select.Option
          key={item.uuid}
          value={item.uuid}
          label={item.type}
          title={item.title}
        >
          <div className={stylesheet.optionContainer}>
            <span>{item.title}</span>
            <span className={stylesheet.optionType}>{item.type}</span>
          </div>
        </Select.Option>
      );
    });
  };

  const customRenderOption = (option: SequenceTag) => (
    <AutoComplete.Option key={option.id} value={option.title}>
      {option.title}
    </AutoComplete.Option>
  );

  return (
    <>
      <div className={stylesheet.tagView}>
        <div
          className={stylesheet.tagCont}>
          <div className={stylesheet.tagTitle}>
            <Select
              open={open}
              onDropdownVisibleChange={(visible) => setOpen(visible)}
              style={{ width: '100%' }}
              placeholder="Tags"
              value={tagId || null}
              onChange={(tagId, value: any) => {
                handleSelectTag(tagId, value.label, value.title);
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <TagCreate projectId={projectId} fetchProjectTags={fetchProjectTags} handleBlur={handleBlur} />
                </>
              )}
            >
              {renderTagsOption(tags)}
            </Select>
          </div>
          <div className={stylesheet.tagValue}>
            {type === 'label' &&
              <AutoComplete
                onChange={val => {
                  setLabelValue(val);
                }}
                value={labelValue}
                dataSource={tagLabels.map(customRenderOption)}
              >
                <Input
                  placeholder="Label"
                  onPressEnter={pressEnter}
                  suffix={<EnterOutlined />}
                />
              </AutoComplete>
            }
            {type === 'scoring' &&
              <EditProbability saveSequenceTagValue={saveSequenceTagValue} />
            }
            {type !== 'label' && type !== 'scoring' &&
              <Input
                disabled={!tagId}
                value={title}
                placeholder={'Value'}
                onChange={handleChange}
                onPressEnter={pressEnter}
                status={error ? 'error' : ''}
                suffix={<EnterOutlined className={stylesheet.enterIcon} />}
              />
            }
          </div>
        </div>
      </div>
      {errorMessage && <div className={stylesheet.errorMessage}>{errorMessage}</div>}
    </>
  );
}
