import _ from 'lodash';

const parseInteger = (value: number, fixed: number) => {
  return (Number.isInteger(value) ? value : value.toFixed(fixed));
};

const statisticsTooltip = (data: Record<string, number>) => {
  let title = `min: ${data.min} \nmax: ${data.max}`;
  if (data.avg) {
    title += `\navg: ${data.avg}`;
  }
  if (data.q25) {
    title += `\n25-Quantil: ${data.q25}`;
  }
  if (data.q50) {
    title += `\nMedian: ${data.q50}`;
  }
  if (data.q75) {
    title += `\n75-Quantil: ${data.q75}`;
  }
  return title;
};

export function NumberCellRenderer(value: string | number | { min: number, max: number, most_common?: any }) {
  if (_.isNumber(value)) {
    return parseInteger(value, 5);
  } else if (_.isObject(value) && !value.most_common) {
    return value?.min && value?.max ? <div title={statisticsTooltip(value)}>{`${parseInteger(value?.min, 2)}, ${parseInteger(value?.max, 2)}`}</div> : '';
  } else if (_.isObject(value) && value.most_common) {
    return value.most_common;
  } else {
    return value;
  }
}
