import _ from 'lodash';
// API
import { BACKEND_BASE_URL } from 'api';
// Types
import { StructureItem } from 'containers/types';
// Helpers
import { buildLink } from './buildLink';
import { Structure } from 'types';

export function getStructures(items: Structure[], seq_id?: string) {
  const sources: StructureItem[] = [];

  _.forEach(items, (item) => {
    const { id, external_id, source } = item;

    let link: JSX.Element | string;
    let source_title: string;
    let ngl_link: string = '';

    if (source === 'proteineer') {
      source_title = 'Proteineer';
      link = buildLink(`${BACKEND_BASE_URL}structure/pdb/${id}`, `${id}.pdb`);
      ngl_link = `${BACKEND_BASE_URL}structure/pdb/${id}`;
    } else if (source === 'alphafolddb' && external_id !== undefined) {
      source_title = 'AlphaFold Protein Structure Database';
      link = buildLink(`https://alphafold.ebi.ac.uk/entry/${external_id}`, external_id);
      ngl_link = `https://alphafold.ebi.ac.uk/files/AF-${external_id}-F1-model_v4.pdb`;
    } else if (source === 'esmatlas' && external_id !== undefined) {
      source_title = 'ESM Metagenomic Atlas';
      link = buildLink(`https://esmatlas.com/explore/detail/MGYP${String(external_id).padStart(12, '0')}`, `MGYP${String(external_id).padStart(12, '0')}`);
      ngl_link = `https://api.esmatlas.com/fetchPredictedStructure/MGYP${String(external_id).padStart(12, '0')}.pdb`;
    } else if (source === 'rcsb' && external_id !== undefined) {
      source_title = 'RCSB';
      link = buildLink(`https://www.rcsb.org/structure/${external_id}`, item.title ? `${external_id}: ${item.title}` : `${external_id}`);
      ngl_link = `https://files.rcsb.org/download/${external_id}.pdb`;
    } else {
      console.log('Unknown source', source);
      source_title = source;
      link = `${source} ${id}`;
    }

    sources.push({
      ...item,
      source_title,
      link,
      ngl_link,
    });
  });

  return sources;
}
