import Aioli from '@biowasm/aioli';

export const renderFastaContent = async (data: { sequence: string }[]) => {
  if (!data.length) {
    return [];
  }

  if (data.length === 1) {
    return [data[0].sequence];
  }

  const CLI = await new Aioli(['kalign/3.3.1']);

  const rows = data.map(({ sequence }: { sequence: string }) => `>1aab_\n${sequence}`).join('\n');

  await CLI.mount({
    name: 'input.fa',
    data: rows,
  });

  await CLI.exec('kalign input.fa -f fasta -o result.fasta');
  const result = await CLI.cat('result.fasta');

  return result.replace(/\n/g, '').split('>1aab_').filter((item: string) => !!item);
};
