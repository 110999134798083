import { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { EnterOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
// Styles
import stylesheet from '../TagTab.module.scss';

type Props = {
  saveSequenceTagValue: (val: number) => void;
  setValue?: (val: number) => void;
};

const probabilityOptions = [{
  label: '-',
  value: <MinusOutlined />,
}, {
  label: '0',
  value: <div className={stylesheet.customRadioOption}>0</div>,
}, {
  label: '+',
  value: <PlusOutlined />,
}];

export function EditProbability({ setValue, saveSequenceTagValue }: Props) {
  const [probability, setProbability] = useState('');
  const [scoring, setScroring] = useState('');

  const handleEnter = (e: any) => {
    let value = e.target.value;
    if (value) {
      if (value === '-') {
        value = -1;
      } else if (value === '+') {
        value = 1;
      } else {
        value = 0;
      }

      setValue?.(value);
      setScroring('');
      saveSequenceTagValue(value);
    }
  };

  const handleChange = (val: string) => {
    if (!val || ['-', '0', '+'].includes(val)) {
      setProbability(val);
    }
  };

  const customPropabilityOption = (item: any) => (
    <AutoComplete.Option key={item.label} value={item.label}>
      {item.value}
    </AutoComplete.Option>
  );

  return (
    <AutoComplete
      dataSource={probabilityOptions.map(customPropabilityOption)}
      onChange={handleChange}
      value={probability}
      >
      <Input
        placeholder="Scoring"
        onPressEnter={handleEnter}
        onChange={(e) => setScroring(e.target.value)}
        value={scoring}
        suffix={<EnterOutlined />}
      />
    </AutoComplete>
  );
}
