import { List } from 'antd';
import _ from 'lodash';
// Types
import { SequenceSource } from 'types';
// Helpers
import { formatSequenceSourceItemWithClassName } from 'helpers/formatSequenceSourceItem';
// Styles
import stylesheet from './SourcesTab.module.scss';

export function SourcesTab({ sources, loading }: { sources: SequenceSource[], loading: boolean }) {
  const getSourceItems = (sequences: any) => {
    if (_.isEmpty(sequences)) return [];

    return sequences;
  };

  return (
    <List
      loading={loading}
      className={stylesheet.list}
      dataSource={getSourceItems(sources)}
      renderItem={formatSequenceSourceItemWithClassName()}
    />
  );
}
