import _ from 'lodash';
// Types
import { Paper } from 'types';
import { PapersItem } from 'containers/types';
// Helpers
import { buildLink } from './buildLink';

export function formatPapersLinks(data: Paper[]) {
  if (_.isEmpty(data)) return [];

  const items: PapersItem[] = [];
  _.forEach(data, ({ pub_med_id, study_acc, title }: { title: string, pub_med_id?: string, study_acc?: string }) => {
    if (study_acc && title) {
      items.push({
        id: study_acc,
        title: title.trim(),
        link: buildLink(`https://www.ncbi.nlm.nih.gov/search/all/?term=${study_acc}`, study_acc),
      });
    } else if (pub_med_id && title) {
      items.push({
        id: pub_med_id,
        title: title.trim(),
        link: buildLink(`https://www.ncbi.nlm.nih.gov/pmc/?term=${pub_med_id}`, pub_med_id),
      });
    }
  });

  return items;
}
