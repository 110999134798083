import { useCallback, useEffect, useState } from 'react';
import { Empty } from 'antd';
import _ from 'lodash';
// Components
import { TitleTooltip } from 'components/TitleTooltip';
import { Hints } from 'components/SequenceTable/columnsData';
import { NameCellRenderer } from 'components/SequenceTable/cells';
// API
import { sequenceApi } from 'api';
// Types
import { SequenceColumn, SequenceFasta } from 'types';
// Helpers
import { OGTValuesRender } from 'helpers/OGTValuesRender';
import { renderFastaContent } from 'helpers/renderFastaContent';
// Styles
import stylesheet from './ProfileTab.module.scss';

type Props = {
  sequence: SequenceColumn | undefined;
  handleNameEdition: (value: string, seq_id: string) => void;
  projectId: string;
  groupId: number;
  readOnly: boolean;
};

export function ProfileTab({ sequence, handleNameEdition, projectId, groupId, readOnly }: Props) {
  const [alignedSequences, setAlignedSequences] = useState([]);
  const [alignedSequencesLoading, setAlignedSequencesLoading] = useState(false);
  const [sequenceFasta, setSequenceFasta] = useState('');

  const fetchSequenceFasta = useCallback((id: string) => {
    id && sequenceApi.getSequenceFasta({ projectId, sequenceId: id })
      .then((data: SequenceFasta) => {
        if (sequence?.nearest_query?.nearest_id && data.sequence) {
          // Fetach nearest Fasta to compare
          fetchSequenceFastaToCompare(sequence.nearest_query.nearest_id, data.sequence);
          setAlignedSequencesLoading(true);
        } else {
          setAlignedSequences([]);
          // Save selected Fasta
          data.sequence && setSequenceFasta(data.sequence);
        }
      })
      .catch((error) => {
        console.error('Get sequence group info data error:', error);
      });
  }, [sequence]);

  const fetchSequenceFastaToCompare = (sequenceId: string, selectedSequence: string) => {
    sequenceId && sequenceApi.getSequenceFasta({ projectId, sequenceId })
      .then((data: any) => {
        if (data.sequence) {
          void renderAioliString(selectedSequence, data.sequence);
        }
      })
      .catch((error) => {
        setAlignedSequencesLoading(false);
        console.error('Get sequence group info data error:', error);
      });
  };

  const renderAioliString = async (line1: string, line2: string) => {
    const result = await renderFastaContent([{ sequence: line1 }, { sequence: line2 }]);

    setAlignedSequencesLoading(false);
    setAlignedSequences(result);
  };

  const renderElem = (elem: number | Record<string, number>, elem2?: number | Record<string, number> | undefined, toFixed: number = 5) => {
    let value = '';

    if (_.isNumber(elem) && !elem2) {
      value = elem.toFixed(toFixed);
    } else if (_.isNumber(elem) && _.isNumber(elem2)) {
      value = (elem / elem2).toFixed(toFixed);
    } else if (_.isObject(elem) && !elem2 && elem.min && elem.max) {
      value = `${elem.min}, ${elem.max}`;
    } else if (_.isObject(elem) && _.isObject(elem2)) {
      if (elem.min && elem.max && elem2.min && elem2.max) {
        value = `${elem.min / elem2.min}, ${elem.max / elem2.max}`;
      } else value = 'unknown';
    } else value = 'unknown';

    return value || '';
  };

  const renderWrappedElem = (elem: (val: string) => JSX.Element, value: number | Record<string, number>, value2?: number | Record<string, number>) => {
    const parsedValue = renderElem(value, value2);
    if (parsedValue) {
      return <div className={stylesheet.rowComplex}>{elem(parsedValue)}</div>;
    } else return null;
  };

  useEffect(() => {
    sequence && fetchSequenceFasta(sequence.id);
  }, [sequence]);

  if (!sequence) {
    return <Empty className={stylesheet.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <>
      <div className={stylesheet.profileList}>
        <div className={stylesheet.rowComplex}><span>Sequence ID:</span> {sequence?.id}</div>
        <div className={stylesheet.rowComplex}><span>Sequence Name:</span>{NameCellRenderer(sequence?.name, sequence, groupId, handleNameEdition, stylesheet.cell, readOnly)}</div>
        {!!sequence?.length && <div className={stylesheet.rowComplex}><span>Length:</span> {renderElem(sequence?.length, undefined, 0)}</div>}
        {!!sequence?.usability && sequence?.solubility && renderWrappedElem((val: string) => <><span>Expression<TitleTooltip hint={Hints.expression} className='leftMargin'/> :</span> {val}</>, sequence?.usability, sequence?.solubility)}
        {!!sequence?.usability && renderWrappedElem((val: string) => <><span>Usability<TitleTooltip hint={Hints.usability} className='leftMargin'/> :</span> {val}</>, sequence?.usability)}
        {!!sequence?.solubility && renderWrappedElem((val: string) => <><span>Solubility<TitleTooltip hint={Hints.solubility} className='leftMargin'/> :</span> {val}</>, sequence?.solubility)}
        {!!sequence?.nearest_query?.similarity && renderWrappedElem((val: string) => <><span>Similarity<TitleTooltip hint={Hints.similarity} className='leftMargin'/> :</span> {val}</>, sequence?.nearest_query?.similarity)}
        {!!sequence?.thermostability && renderWrappedElem((val: string) => <><span>Thermostability Prediction<TitleTooltip hint={Hints.thermostability} className='leftMargin'/> :</span> {val}</>, sequence?.thermostability)}
        {!!sequence?.nearest_structure_query?.rmsd && renderWrappedElem((val: string) => <><span>RMSD<TitleTooltip hint={Hints.nearest_query_structure_rmsd} className='leftMargin'/> :</span> {val}</>, sequence?.nearest_structure_query.rmsd)}
        {!!sequence?.nearest_structure_query?.tm && renderWrappedElem((val: string) => <><span>TM-Score<TitleTooltip hint={Hints.nearest_query_structure_tm} className='leftMargin'/> :</span> {val}</>, sequence?.nearest_structure_query.tm)}

        <div className={stylesheet.rowComplex}>{OGTValuesRender(sequence, stylesheet.lineTitle)}</div>
      </div>

      {!!alignedSequences.length && <div className={stylesheet.rowSimple}><span>Sequence Alignment:</span></div>}
      {!!alignedSequences.length && (
        <div className={stylesheet.list}>
          <div className={stylesheet.sequenceTitle}><div className={stylesheet.hit}>Hit:</div>{alignedSequences[0]}</div>
          <div className={stylesheet.sequenceTitle}><div className={stylesheet.query}>Query:</div>{alignedSequences[1]}</div>
        </div>
      )}
      {!alignedSequencesLoading && !alignedSequences.length && sequenceFasta && (
        <div className={stylesheet.list}>
          <div className={stylesheet.sequenceTitle}>{sequenceFasta}</div>
        </div>
      )}
    </>
  );
}
