import { List } from 'antd';
// Types
import { Paper } from 'types';
import { PapersItem } from 'containers/types';
// Helpers
import { formatPapersLinks } from 'helpers/formatPapersLinks';
// Styles
import stylesheet from './PapersTab.module.scss';

export function PapersTab({ papers, loading }: { papers: Paper[], loading: boolean }) {
  const renderItem = ({ id, title, link }: PapersItem) => {
    return (<List.Item key={id}>{link}: {title}</List.Item>);
  };

  return (
    <List
      loading={loading}
      className={stylesheet.list}
      dataSource={formatPapersLinks(papers)}
      renderItem={renderItem}
    />
  );
}
