import { useMemo, useState } from 'react';
import cx from 'classnames';
import { Button, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
// Types
import { Config } from 'types';
// Data
import { Columns } from '../columnsData';
import { ColumnListOrder } from './ColumnListOrder';
// Styles
import stylesheet from './Preferences.module.scss';
import _ from 'lodash';

type Column = Record<string, boolean>;

interface Props {
  columns: Column;
  tagColumns: Column;
  updateColumns: (list: any) => void;
  onOk: () => void;
  onCancel: any;
  frontendConfig: Config['frontendConfig'];
}

export function Preferences({ columns, tagColumns, updateColumns, onOk, onCancel, frontendConfig }: Props) {
  const data = Columns;
  const [list, updateList] = useState({ ...tagColumns, ...columns });

  const onCheck = ({ target }: CheckboxChangeEvent, item: string) => {
    updateList((state: Record<string, boolean>) => {
      return {
        ...state,
        [item]: target.checked
      };
    });
  };

  const onCheckSubCategory = ({ target }: CheckboxChangeEvent, items: string[]) => {
    updateList((state: Record<string, boolean>) => {
      return {
        ...state,
        ..._.zipObject(items, Array(items.length).fill(target.checked))
      };
    });
  };

  const selectAll = (select: boolean) => {
    updateList(Object.keys(list).reduce((o, key: any) => ({ ...o, [key]: !select }), {}));
  };

  const handleSave = () => {
    updateColumns(list);
    onOk();
  };

  const handleCancel = () => {
    updateList(columns);
    onCancel();
  };

  const renderColumnItem = (item: string) => {
    return (
      <Checkbox
        key={item}
        className={stylesheet.checkbox}
        checked={list[item]}
        onChange={(value) => onCheck(value, item)}>
        {data[item] || item}
      </Checkbox>
    );
  };

  const renderColumn = (item: any) => {
    if (item.subList) {
      return (
        <div key={item.title} className={stylesheet.subList}>
          <Checkbox
            className={stylesheet.checkbox}
            checked={!item.subList.some((item: { key: string, value: string }) => !list[item.key])}
            onChange={(value) => {
              const subList = item.subList.map((item: { key: string, value: string }) => item.key);
              onCheckSubCategory(value, subList);
            }}>
            {item.title}
          </Checkbox>
          <div className={stylesheet.subListCheckbox}>
            {item.subList.map(renderColumn)}
          </div>
        </div>
      );
    }

    if (_.has(columnList, item.key)) {
      return (
        <Checkbox
          key={item.key}
          className={stylesheet.checkbox}
          checked={list[item.key]}
          onChange={(value) => onCheck(value, item.key)}>
          {item.value}
        </Checkbox>
      );
    }

    return null;
  };

  const allSelected = !Object.values(list).some(item => !item);
  const tagColumnsKeys = Object.keys(tagColumns);

  const columnList = useMemo(() => {
    const saved = _.clone(list);
    // Filter out tag columns if not permitted
    if (frontendConfig?.tags.enable === false) {
      _.each(_.keys(list), item => {
        if (!_.keys(data).includes(item)) {
          delete saved?.[item];
        }
      });
    }

    return saved;
  }, [list]);

  return (
    <div className={stylesheet.root}>
      <Checkbox
        key='all'
        className={cx(stylesheet.checkbox, stylesheet.selectAll)}
        checked={allSelected}
        onChange={() => selectAll(allSelected)}>
        Select all
      </Checkbox>

      <div className={stylesheet.columnList}>
        {ColumnListOrder.map(renderColumn)}
        {!!tagColumnsKeys.length &&
          <div className={stylesheet.subList}>
            <Checkbox
              className={stylesheet.checkbox}
              checked={!tagColumnsKeys.some(item => !list[item])}
              onChange={(value) => {
                onCheckSubCategory(value, tagColumnsKeys);
              }}>
              Tags
            </Checkbox>
            <div className={stylesheet.subListCheckbox}>
              {tagColumnsKeys.map(renderColumnItem)}
            </div>
          </div>
        }
      </div>
      <div className={stylesheet.control}>
        <Button type='default' onClick={handleCancel}>Cancel</Button>
        <Button type='primary' onClick={handleSave}>Save</Button>
      </div>
    </div>
  );
}
