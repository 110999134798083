export const FORM_VALIDATION_RULES = {
  EMAIL: [{
    required: true,
    message: 'Please input your username!',
  }],
  PASSWORD: [{
    required: true,
    message: 'Please input your password!',
  }]
};
