import { Popover, Space, Tag, Typography } from 'antd';
import _ from 'lodash';

import { getSourceLabels } from 'components/SequenceTable/helpers';
import { Sequence } from 'types';

import stylesheet from './SourceCellRenderer.module.scss';
import { Link } from 'react-router-dom';
import { DataSource } from 'common/DataSources';

export function SourceCellRenderer(text: any, record: Sequence | null, label?: DataSource[]) {
  const labels = ((record && getSourceLabels(record)) ?? label) ?? [];

  const isUrl = (s: string) => {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/;

    return regexp.test(s);
  };

  const renderDescription = (list: any = []) => {
    return list.map((item: any) => {
      if (typeof item === 'string') {
        return (
          <Typography.Paragraph key={item} ellipsis={{ tooltip: item, rows: 6 }}>{isUrl(item) ? <Link to={item}>{item}</Link> : item}</Typography.Paragraph>
        );
      } else {
        return renderDescription([item.props.children]);
      }
    });
  };

  const singleLabel = (title: string) => {
    return (
      <Tag className={stylesheet.tag}>
        <Typography.Text ellipsis={{ tooltip: title }}>{title}</Typography.Text>
      </Tag>
    );
  };

  const renderLabels = (labels: any) => _.map(labels, (label, index) => {
    const children = label?.description?.props?.children;
    const description = typeof children === 'string' ? [children] : children;

    const popoverContent = () => (
      <Space className={stylesheet.popoverContent} wrap size={[0, 6]}>
        {renderDescription(description)}
      </Space>
    );

    return (
      <div
        key={`${label.title as string}_${index}`}
        className={label.description}>
        {label.description
        ? (
            <Popover
              title="Description"
              content={popoverContent}
              trigger="hover"
            >
              <Space className={stylesheet.root} wrap size={[0, 6]}>
                {singleLabel(label.title)}
              </Space>
            </Popover>
          )
        : singleLabel(label.title)}
      </div>
    );
  });

  if (labels.length > 2) {
    const labelsToShow = [labels[0], 'more...'];

    const popoverContent = () => (
      <Space className={stylesheet.popoverContent} wrap size={[0, 6]}>
        {renderLabels(labels)}
      </Space>
    );

    return (
      <Popover
        title="Sources"
        content={popoverContent}
        trigger="hover"
        placement="leftTop"
      >
        <Space className={stylesheet.root} size={[0, 6]}>
          {renderLabels(labelsToShow)}
        </Space>
      </Popover>
    );
  }

  return (
    <div className={stylesheet.root}>
      {renderLabels(labels)}
    </div>
  );
}
