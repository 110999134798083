import { ColumnType } from 'antd/es/table';
import { SequenceColumn, SequenceData, SequenceTag } from 'types';

import { Condition as SourceFilterCondition } from './filters/SourceFilter';

export type SequenceColumns = Array<ColumnType<SequenceColumn>>;

export enum FilterType {
  SEARCH = 'SEARCH',
  NUMBER_RANGE = 'NUMBER_RANGE',
  SOURCE = 'SOURCE',
  // Additional
  LABEL_TAG = 'LABEL_TAG',
}

export type SourceFilterValue = Record<string, SourceFilterCondition>;

export type NumberRangeFilter = {
  type: FilterType.NUMBER_RANGE;
  value: { min?: number, max?: number };
  bounds?: { min?: number | null, max?: number | null };
};

export type SourceFilter = {
  type: FilterType.SOURCE;
  value: SourceFilterValue | undefined;
};

export type LabelTagFilter = {
  type: FilterType.LABEL_TAG;
  value: number | undefined;
};

export type SearchFilter = {
  type: FilterType.SEARCH;
  value: string | undefined;
};

export type ColumnFilter = SearchFilter | NumberRangeFilter | SourceFilter | LabelTagFilter;

export type OnFilter = (column: string, value: ColumnFilter | undefined) => void;
export type OnFilterReset = (column: string) => void;

export type FilterCollection = Record<string, ColumnFilter>;
export type FilterProps<Value = any> = {
  column: string;
  columnTitle: string;
  value: Value | undefined;
  bounds?: {
    min?: number | null | undefined;
    max?: number | null | undefined;
} | undefined;
  onFilter: OnFilter;
  onFilterReset: OnFilterReset;
  allData: SequenceData;
  dataIndex: string;
  labelTagsValues: Record<string, SequenceTag[]> | undefined;
};
