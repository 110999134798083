import { Result } from 'antd';

import stylesheet from './EmptyPage.module.scss';

export function EmptyPage() {
  return (
    <Result
      className={stylesheet.root}
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  );
}
