// Types
import { Cluster, Sequence, SequenceTag, Tag } from 'types';

export function TagCellRenderer(name: string, sequence: Sequence & Cluster | undefined, col: number, tag: Tag, labelTagsValues?: Record<string, SequenceTag[]>): JSX.Element {
  let value = name;
  if (tag.type === 'label') {
    // Extract value of the label tag by tag id
    const labelTag = labelTagsValues?.[tag.uuid]?.find(item => item.id === parseInt(name))?.title;
    value = labelTag ?? name;
  };

  return (
    <div>
      {value}
    </div>
  );
}
