// Phylocanvas.gl (https://phylocanvas.gl)
// Centre for Genomic Pathogen Surveillance.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.

/* eslint-disable no-lonely-if */

import isEmoji from "./emoji";

import shapeRenderers from "./shapes";

export default function drawVectorShape(shape, x, y, radius, fillColour, borderColour, borderWidth) {
  if (radius <= 0 || shape === "none") {
    return "";
  }

  const emoji = isEmoji(shape);

  if (emoji) {
    return `<text x="${x}" y="${y}" font-size="${radius * 2}px" font-family="Segoe UI Emoji" text-anchor="middle" dominant-baseline="middle" fill="${fillColour}">${emoji}</text>`;
  }

  else if (shape in shapeRenderers) {
    return shapeRenderers[shape].renderSvg(
      x,
      y,
      radius,
      `fill="${fillColour}" stroke="${borderColour}" stroke-width="${borderWidth}"`,
    );
  }

  else {
    throw new Error(`Invalid shape ${shape}`);
  }
}
