import { EmptyArray, TreeTypes } from "./constants";

export default Object.freeze({
  alignLabels: false,
  backgroundColour: null,
  blockHeaderFontSize: 13,
  blockPadding: 4,
  blocks: [],
  blockSize: 16,
  branchLengthsDigits: 4,
  branchLengthsFormat: null,
  branchZoom: 0,
  centre: [ 0.5, 0.5 ],
  collapsedIds: EmptyArray,
  edgeOverlapFactor: -0.5,
  fillColour: [ 51, 51, 51, 255 ], // "#333333"
  fontColour: [ 51, 51, 51, 255 ], // "#333333"
  fontFamily: "monospace",
  fontSize: 16,
  haloRadius: 12,
  haloWidth: 4,
  highlightColour: [ 60, 115, 131, 255 ], // "#3C7383"
  interactive: false,
  metadata: {},
  minScale: 0.01,
  nodeOverlapFactor: 0.1,
  nodeShape: "circle",
  nodeSize: 14,
  padding: 16,
  rootId: null,
  rotatedIds: EmptyArray,
  scaleLineAlpha: false,
  selectedIds: EmptyArray,
  shapeBorderAlpha: 0.28,
  shapeBorderWidth: 1,
  showBlockHeaders: false,
  showBlockLabels: false,
  showBranchLengths: false,
  showEdges: true,
  showInternalLabels: false,
  showLabels: false,
  showLeafLabels: false,
  showPiecharts: false,
  showShapeBorders: false,
  showShapes: true,
  size: { height: 150, width: 300 }, // default canvas size
  stepZoom: 0,
  strokeColour: [ 34, 34, 34, 255 ], // "#222222"
  strokeWidth: 1,
  styleLeafLabels: false,
  styleNodeEdges: false,
  styles: {},
  treeToCanvasRatio: 0.25,
  type: TreeTypes.Rectangular,
  zoom: 0,
});
