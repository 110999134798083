import { useState } from 'react';
import { Button, Input, Modal, Select } from 'antd';
// API
import { sequenceApi } from 'api';
// Styles
import stylesheet from './TagCreate.module.scss';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

type Props = {
  projectId: string;
  fetchProjectTags: () => void;
  handleBlur: () => void;
};

export function TagCreate({ projectId, fetchProjectTags, handleBlur }: Props) {
  const [opened, setOpened] = useState(false);
  const [title, setTitle] = useState('');
  const [type, setType] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCancel = () => {
    setType(null);
    setTitle('');
    setOpened(false);
  };

  const handleSelectChange = (value: string) => {
    setType(value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleCreate = () => {
    if (title && type) {
      projectId && sequenceApi.createTag({ projectId, title, type })
        .then(() => {
          fetchProjectTags();
          handleCancel();
        })
        .catch(e => {
          setErrorMessage(e.message);
        });
    }
  };

  return (
    <>
      <Button
        className={stylesheet.addNewButton}
        icon={<PlusOutlined />}
        onClick={() => {
          setOpened(true);
          handleBlur();
        }}>
        Add new Tag
      </Button>

      <Modal
        title="Add new Tag"
        onCancel={handleCancel}
        open={opened}
        width={350}
        footer={[
          <div key="buttons">
            <Button key="cancel" onClick={handleCancel}>Cancel</Button>
            <Button
              key="create"
              disabled={!title || !type}
              onClick={handleCreate}
              type="primary">
              Create
            </Button>
          </div>
        ]}
      >
        <div className={stylesheet.contentForm}>
          <div className={stylesheet.tagValueCont}>
            <div className={stylesheet.inputs}>
              <Input
                value={title}
                placeholder='Title'
                onChange={handleChange}
              />
              <Select
                style={{ width: '100%' }}
                placeholder="Tag type"
                value={type}
                onChange={handleSelectChange} // Callback function when an option is selected
              >
                <Option value="label">Label</Option>
                <Option value="number">Number</Option>
                <Option value="probability">Probability</Option>
                <Option value="scoring">Scoring</Option>
              </Select>
            </div>
            {errorMessage && <div className={stylesheet.errorMessage}>{errorMessage}</div>}
          </div>
        </div>
      </Modal>
    </>
  );
}
