import { List } from 'antd';
import _ from 'lodash';
// Types
import { Location } from 'types';
// Helpers
import { formatLocationLinks } from 'helpers/formatLocationLinks';
// Styles
import stylesheet from './LocationTab.module.scss';

type Props = {
  hideEmpty?: boolean;
  locations: Location[];
  loading: boolean;
};

type LocationItem = {
  id: string;
  title: string;
  source: string | number;
  link: string | JSX.Element;
  coords: string | JSX.Element;
};

export function LocationTab({ hideEmpty = false, locations, loading }: Props) {
  const renderItem = ({ id, source, link, title, coords }: LocationItem) => {
    return (<List.Item key={id}>{source}{link && ' '}{link}: {title}{title && ' '}{coords}</List.Item>);
  };

  // Prevent from showing empty list
  if (hideEmpty && _.isEmpty(locations)) {
    return null;
  }

  return (
    <List
      loading={loading}
      className={stylesheet.list}
      dataSource={formatLocationLinks(locations)}
      renderItem={renderItem}
    />
  );
}
