import { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import stylesheet from './Breadcrubs.module.scss';
import { Project } from 'types';
import { sequenceApi } from 'api';

export function Breadcrumbs() {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId, groupId, clusterId } = useParams();

  const [projects, setProjects] = useState<Array<Project>>([]);
  const { pathname, search } = location;
  const title = new URLSearchParams(search).get('title');
  const parents = new URLSearchParams(search).get('parents');
  const pathnames = pathname.split('/').filter((item) => item);
  const parentsList = parents?.split(',');

  useEffect(() => {
    sequenceApi.getUserProjects()
      .then((projects) => {
        setProjects(projects);
      })
      .catch((error) => {
        console.error('Get user projects failed:', error);
      });
  }, []);

  const itemRender = (route: any) => {
    return (
      <span
        className={stylesheet.linkItem}
        onClick={(e) => {
          // This is imitates Link behaviour
          e.preventDefault();
          navigate(route.href);
        }}>
          {route.title}
      </span>
    );
  };

  const getProjectName = () => {
    let name = '';

    if (projects && projectId) {
      const project = projects.find((item: any) => item.project_uuid === projectId);
      name = project?.title ?? name;
    }
    return name;
  };

  if (!projectId) return null;

  const items = [{ title: 'Projects', href: '/' }];
  if (pathnames.includes('groups') && projectId) items.push({ title: getProjectName(), href: `/projects/${projectId}/groups` });
  if (!pathnames.includes('sequences') && projectId && groupId && !clusterId) items.push({ title: 'Sequence Group', href: `/projects/${projectId}/groups/${groupId}` });
  if (pathnames.includes('sequences') && projectId && groupId) items.push({ title: 'Sequences', href: `/projects/${projectId}/groups/${groupId}/sequences` });
  if (pathnames.includes('structure') && projectId && groupId) items.push({ title: `Structure${title ? ` - ${title}` : ''}`, href: `/projects/${projectId}/groups/${groupId}/sequences/structure${search}` });
  if (pathnames.includes('compare') && projectId && groupId) items.push({ title: `Compare${title ? ` - ${title}` : ''}`, href: `/projects/${projectId}/groups/${groupId}/sequences/compare` });
  if (pathnames.includes('clusters') && projectId && groupId && clusterId && parentsList?.length) {
    parentsList.forEach((item, index) => {
      const querys = parentsList.slice(0, index + 1);
      items.push({ title: `${item.substring(0, 5)}...`, href: `/projects/${projectId}/groups/${groupId}/clusters/${item}?parents=${querys.join(',')}` });
    });
  }
  if (pathnames.includes('msa') && pathnames.includes('sequences') && projectId && groupId) items.push({ title: 'MSA Viewer', href: `/projects/${projectId}/groups/${groupId}/sequences/msa` });
  if (pathnames.includes('msa') && pathnames.includes('clusters') && projectId && groupId) items.push({ title: 'MSA Viewer', href: `/projects/${projectId}/groups/${groupId}/clusters/msa` });

  return (
    <div className={stylesheet.wrapper}>
      <Breadcrumb itemRender={itemRender} items={items} />
    </div>
  );
};
