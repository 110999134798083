import { SequenceColumn } from 'types';
import { SequenceData } from 'containers/CompareView/types';
import { formatNumber } from './formatNumber';

export const OGTValuesRender = (seq: SequenceData | SequenceColumn, className: string = '') => {
  const formatOGTValue = (name: string, val: number | undefined) => {
    if (val !== undefined && formatNumber(val, 5)) {
      return `${name}=${formatNumber(val, 5)} `;
    } else {
      return '';
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (!['ogt_t40', 'ogt_t45', 'ogt_t50', 'ogt_t55', 'ogt_t60', 'ogt_t65'].some((it: string) => seq[it] && formatNumber(seq[it], 5))) {
    return null;
  } else {
    return (
      <><span className={className}>OGT:</span> {formatOGTValue('t40', seq.ogt_t40)}{formatOGTValue('t45', seq.ogt_t40)}{formatOGTValue('t50', seq.ogt_t50)}{formatOGTValue('t55', seq.ogt_t55)}{formatOGTValue('t60', seq.ogt_t60)}{formatOGTValue('t65', seq.ogt_t65)}</>
    );
  }
};
