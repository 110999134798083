export function isGenericNumber(value: any) {
  // Check if the value is a number
  if (typeof value !== 'number' || isNaN(value)) {
    return false;
  }

  // Check if the value is finite (not +inf or -inf)
  if (!isFinite(value)) {
    return false;
  }

  return true;
};

export function isNumberInRange(value: any) {
  // Check if the value is a number
  if (typeof value !== 'number' || isNaN(value)) {
    return false;
  }

  // Check if the value is within the range [0, 1]
  return value >= 0 && value <= 1;
}

// export function isNumberInScoringRange(value: any) {
//   // Check if the value is a number
//   if (typeof value !== 'number' || isNaN(value)) {
//     return false;
//   }

//   // Check if the value is within the range [0, 1]
//   return value >= -1 && value <= 1;
// }

export const roundToNearestRenderableValue = (value: any) => {
  const renderableValues = [-1, 0, 1];
  return renderableValues.reduce((prev, curr) =>
    Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
  );
};

export const parseProbabilityValue = (value: number) => {
  if (value === -1) {
    return '-';
  } else if (value === 1) {
    return '+';
  } else return value;
};
