import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import cx from 'classnames';

import { BlockOutlined } from '@ant-design/icons';
import { DownloadButton } from 'components/DownloadButton';
// API
import { SEQUENCES_LIMIT } from 'api';
// Types
import { SequenceData } from 'types';
// Styles
import stylesheet from './Footer.module.scss';

type Props = {
  seqsList: SequenceData;
  selectedSequences: string[];
  sequenceGroup: string;
  groupId: number;
  total: number;
  projectId: string;
};

export function Footer({ seqsList, selectedSequences, sequenceGroup, groupId, total, projectId }: Props): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const linkToCompare = () => {
    let query = '?';
    selectedSequences.forEach((item: string, index: number) => {
      query += `&sequencesId${index}=${item}`;
    });
    // Add an id to compare if only one seq selected and if available
    if (selectedSequences.length === 1) {
      const seq2 = seqsList.find(it => it.id === selectedSequences[0])?.nearest_query?.nearest_id;
      if (seq2) query += `&sequencesId1=${seq2}`;
    }

    navigate(`${pathname}/compare${query}`);
  };

  const limit = parseInt(SEQUENCES_LIMIT);

  return (
    <div className={cx(stylesheet.footer, total === limit ? stylesheet.total : '')}>
      {total === limit && <div>Not all sequence can be shown because the sequence group is too large.</div>}
      <div className={stylesheet.control}>
        <Button
          type='primary'
          style={{ display: 'none' }}
          id='compareButton'
          onClick={linkToCompare}
          icon={<BlockOutlined />}
        >
          Compare
        </Button>
        <DownloadButton
          className={stylesheet.downloadButton}
          sequenceGroup={sequenceGroup}
          projectId={projectId}
          groupId={groupId}
        />
      </div>
    </div>
  );
}

export function getFooter(props: Props) {
  return function FooterWrapper() {
    return (<Footer {...props} />);
  };
}
