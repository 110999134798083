import { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { useAuth } from 'hooks';
import { LoginPayload } from 'types';

import { FORM_VALIDATION_RULES } from './helpers';

import stylesheet from './Login.module.scss';

interface Props {
  onSubmit: (showUpdatePasswordForm: boolean) => void;
}

export function LoginForm({ onSubmit }: Props): JSX.Element {
  const auth = useAuth();

  const [form] = Form.useForm();
  const [error, setError] = useState(false);

  const handleFromChange = () => {
    setError(false);
  };

  const handleSubmit = (payload: LoginPayload) => {
    auth?.login && auth.login(payload)
      .then(({ passwordUpdated }) => {
        onSubmit(!passwordUpdated);
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <Form
      id="loginForm"
      name="loginForm"
      form={form}
      requiredMark={false}
      onChange={handleFromChange}
      onFinish={handleSubmit}
    >
      <Form.Item name="email" rules={FORM_VALIDATION_RULES.EMAIL}>
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>

      <Form.Item name="password" rules={FORM_VALIDATION_RULES.PASSWORD}>
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Password"
        />
      </Form.Item>

      {error && <div className={stylesheet.error}>Login was invalid. Please ty again.</div>}

      <Form.Item>
        <Button
          className={stylesheet.loginButton}
          type="primary"
          htmlType="submit"
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  );
}
