import { SequenceSource } from 'types';
import { buildLink } from 'helpers/buildLink';
import { parseSourceTitle } from 'helpers/parseSourceTitle';
import { dataSources } from 'common/DataSources';
import { List, Typography } from 'antd';
import { SourceCellRenderer } from 'components/SequenceTable/cells';

export const formatSequenceSourceItemWithClassName = (className: string | undefined = undefined) => {
  const formatSequenceSourceItem = (sourceItem: SequenceSource) => {
    const parsedTitle = sourceItem.title && parseSourceTitle(sourceItem.title);

    const label = dataSources[sourceItem.source];

    if (sourceItem.source === 'sra') {
      if (sourceItem.sra_experiment_acc) {
        let title = sourceItem.sra_experiment_title;
        if (title === undefined || title.length === 0) {
          title = sourceItem.title;
        }
        if (title === undefined || title.length === 0) {
          title = '';
        }

        return (
          <List.Item key={`sra_experiment_${sourceItem.sra_experiment_acc}`} className={className}>
            {label && SourceCellRenderer('', null, [label])}
            {buildLink(`https://www.ncbi.nlm.nih.gov/sra/?term=${sourceItem.sra_experiment_acc}`, sourceItem.sra_experiment_acc)}
            <>&nbsp;</>
            <Typography.Text ellipsis>{title}</Typography.Text>
          </List.Item>
        );
      } else if (sourceItem.sra_sample_acc) {
        let title = sourceItem.sra_sample_scientific_name;
        if (title === undefined || title.length === 0) {
          title = sourceItem.title;
        }
        if (title === undefined || title.length === 0) {
          title = '';
        }

        return (
          <List.Item key={`sra_sample_${sourceItem.sra_sample_acc}`} className={className}>
            {label && SourceCellRenderer('', null, [label])}
            {buildLink(`https://www.ncbi.nlm.nih.gov/biosample/?term=${sourceItem.sra_sample_acc}`, sourceItem.sra_sample_acc)}
            <>&nbsp;</>
            <Typography.Text ellipsis>{title}</Typography.Text>
          </List.Item>
        );
      } else {
        // This case should never happen, fallback to default return at end of function.
      }
    } else if (sourceItem.source === 'uniprot' && sourceItem.external_id) {
      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.uniprot.org/uniprotkb/${sourceItem.external_id}/entry`, sourceItem.external_id)}
          <>&nbsp;</>
          {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
        </List.Item>
      );
    } else if (sourceItem.source === 6 && sourceItem.external_id) {
      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.ebi.ac.uk/merops/cgi-bin/aaseq?mernum=${sourceItem.external_id}`, sourceItem.external_id)}
          <>&nbsp;</>
          {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
        </List.Item>
      );
    } else if (sourceItem.source === 7 && sourceItem.external_id) {
      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.genome.jp/entry/${sourceItem.external_id}`, sourceItem.external_id)}
          <>&nbsp;</>
          {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
        </List.Item>
      );
    } else if (sourceItem.source === 9 && sourceItem.external_id) {
      const reducedId = sourceItem.external_id.split('|')[1];

      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.bv-brc.org/search/?keyword(%22${reducedId}%22)`, sourceItem.external_id)}
          <>&nbsp;</>
          {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
        </List.Item>
      );
    } else if ((sourceItem.source === 10 || sourceItem.source === 'rcsb') && sourceItem.external_id) {
      const reducedId = sourceItem.external_id.split('_')[0];

      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.rcsb.org/structure/${reducedId}`, sourceItem.external_id)}
          <>&nbsp;</>
          {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
        </List.Item>
      );
    } else if (sourceItem.source === 11 && sourceItem.external_id) {
      const reducedId = sourceItem.external_id.split('.')[0];

      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.ebi.ac.uk/interpro/protein/unreviewed/${reducedId}/`, sourceItem.external_id)}
          <>&nbsp;</>
          {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
        </List.Item>
      );
    } else if (sourceItem.source === 12 && sourceItem.external_id) {
      const reducedId = sourceItem.external_id.split('|')[1];

      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.uniprot.org/uniprotkb/${reducedId}/entry`, sourceItem.external_id)}
          <>&nbsp;</>
          {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
        </List.Item>
      );
    } else if ((sourceItem.source === 13 || sourceItem.source === 4) && sourceItem.external_id) {
      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.ncbi.nlm.nih.gov/protein/${sourceItem.external_id}/`, sourceItem.external_id)}
          <>&nbsp;</>
          {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
        </List.Item>
      );
    } else if (sourceItem.source === 15 && sourceItem.external_id) {
      const reducedId = sourceItem.external_id.split('_')[0];

      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.ncbi.nlm.nih.gov/search/all/?term=${reducedId}`, sourceItem.external_id)}
        </List.Item>
      );
    } else if (sourceItem.source === 22 && sourceItem.external_id) {
      const taxid = sourceItem.external_id.split('.')[0];
      const biosample = sourceItem.external_id.split('.')[1];
      const gca = sourceItem.external_id.split('.')[2];
      const gcaReduce = gca.split('_')[1];

      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=${taxid}`, taxid)}
          <>.</>
          {buildLink(`https://www.ncbi.nlm.nih.gov/biosample/?term=${biosample}`, biosample)}
          <>.</>
          {buildLink(`https://gtdb.ecogenomic.org/genome?gid=GCA_${gcaReduce}.1`, gca)}
          <>&nbsp;</>
          {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
        </List.Item>
      );
    } else if (sourceItem.source === 27 && sourceItem.external_id) {
      return (
        <List.Item key={`${sourceItem.source}_${sourceItem.external_id}`} className={className}>
          {label && SourceCellRenderer('', null, [label])}
          {buildLink(`https://www.ncbi.nlm.nih.gov/search/all/?term=${sourceItem.external_id}`, sourceItem.external_id)}
          <>&nbsp;</>
          {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
        </List.Item>
      );
    }
    return (
      <List.Item key={`${sourceItem.source}_${sourceItem.external_id ? sourceItem.external_id : ''}`} className={className}>
        {label && SourceCellRenderer('', null, [label])}
        {sourceItem.external_id}
        <>&nbsp;</>
        {parsedTitle && <Typography.Text ellipsis>{parsedTitle}</Typography.Text>}
      </List.Item>
    );
  };
  return formatSequenceSourceItem;
};
