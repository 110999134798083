export const queryParams = (link: string, queryParam: { filters?: string, limit?: string, sort?: string }) => {
  const { filters, limit, sort } = queryParam;
  if (filters) {
    link += `?filter=${filters}`;
  }
  if (sort) {
    link += `${filters ? '&' : '?'}sort=${sort}`;
  }
  if (limit) {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    link += `${(filters || sort) ? '&' : '?'}limit=${limit}`;
  }

  return link;
};
