import { useEffect, useState } from 'react';
import { Button, Select, Space } from 'antd';

import { FilterDropdownProps } from 'antd/es/table/interface';
import { FilterProps, FilterType } from 'components/SequenceTable/types';

import stylesheet from './SearchFilter.module.scss';

type Props = FilterProps & FilterDropdownProps;

export function LabelTagFilter(props: Props): JSX.Element {
  const [value, setValue] = useState<number | undefined>(props.value?.min ?? undefined);

  const { labelTagsValues } = props;
  const tagLabels = labelTagsValues?.[props.dataIndex] ?? [];

  const handleChange = (value: number) => {
    setValue(value);
  };

  const handleApply = (closeDropdown = false) => {
    if (value) {
      props.onFilter(props.column, {
        type: FilterType.NUMBER_RANGE,
        value: { min: value, max: value },
      });
    } else {
      props.onFilter(props.column, undefined);
    }

    props.confirm({ closeDropdown });
  };

  const handleClear = () => {
    setValue(undefined);
    props.onFilter(props.column, undefined);
  };

  const handleReset = () => {
    setValue(undefined);
    props.onFilterReset(props.column);
    props.confirm();
  };

  useEffect(() => {
    if (props.visible) {
      setValue(props.visible ? props.value?.min ?? undefined : undefined);
    }
  }, [props.visible]);

  return (
    <div style={{ padding: 8 }}>
      <Select
        className={stylesheet.input}
        options={tagLabels.map(item => ({ value: item.id, label: item.title }))}
        onChange={handleChange}
        value={value}
      />

      <Space>
        <Button
          size="small"
          className={stylesheet.button}
          onClick={() => handleReset()}
        >
          Reset
        </Button>
        <Button
          size="small"
          className={stylesheet.button}
          onClick={() => handleClear()}
        >
          Clear
        </Button>
        <Button
          size="small"
          type="primary"
          className={stylesheet.button}
          onClick={() => handleApply(true)}
        >
          Apply
        </Button>
      </Space>
    </div>
  );
}

export function getLabelTagFilter(props: FilterProps) {
  return function LabelTagFilterWrapper(dropdownProps: FilterDropdownProps): JSX.Element {
    return (<LabelTagFilter {...props} {...dropdownProps} />);
  };
}
