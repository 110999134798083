import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';

import stylesheet from './TitleTooltip.module.scss';
import { TitleTooltipType } from './types';

export function TitleTooltip({ title = '', hint, className }: TitleTooltipType): JSX.Element {
  return (
    <>
      <div className={stylesheet.root}>
        <Tooltip
          title={() => {
            return (
              <div onClick={(e) => {
                e.stopPropagation();
              }}>
                <span className={stylesheet.tooltipHint}>
                  {hint}
                </span>
              </div>
            );
          }}>
            <span>{title}</span>
            <InfoCircleOutlined className={cx(stylesheet.iconInfo, className && stylesheet[className])} />
        </Tooltip>
      </div>
    </>
  );
}
