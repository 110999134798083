import { Result } from 'antd';

import stylesheet from './ErrorPage.module.scss';

interface Props {
  setConnection: (val: boolean) => void;
  retryCount: number;
};

export function ErrorPage({ setConnection, retryCount }: Props) {
  return (
    <Result
      className={stylesheet.root}
      status="500"
      title="Sorry, something went wrong!"
      subTitle={`Connection retrying... (${retryCount})`}
    />
  );
}
