import { useEffect, useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
// antd
import { Button, Empty, List, Typography } from 'antd';
import { RadarChartOutlined } from '@ant-design/icons';
import { createPluginUI } from 'molstar/lib/mol-plugin-ui/react18';
import { PluginUIContext } from 'molstar/lib/mol-plugin-ui/context';
// API
import { createStructurePrediction } from 'api/sequence';
import { sequenceApi } from 'api';
// Helpers
import { getStructures } from 'helpers/getStructures';
// Types
import { Structure } from 'types';
import { StructureItem } from 'containers/types';
// Styles
import stylesheet from './StructureTab.module.scss';
import './StructureTab.scss';

type Props = {
  sequenceId: string;
  structures: Structure[];
  loading: boolean;
};

declare global {
  interface Window {
    molstar?: PluginUIContext;
  }
}

export const getSourceItems = (structures: Structure[], sequenceId: string) => {
  if (_.isEmpty(structures)) return [];

  const sources = getStructures(structures, sequenceId);

  if (sources.length === 0 && sequenceId) {
    void createStructurePrediction(sequenceId);
  }

  return sources;
};

export const buildStructure = (structures: Structure[], sequenceId: string) => {
  const items = getSourceItems(structures, sequenceId);
  const firstInRow = items[0];

  if (firstInRow) {
    // pdb file will be loaded from external resorces
    if (firstInRow.external_id !== undefined) {
      _.delay(() => {
        return createNGLStructure(firstInRow.ngl_link, true);
      }, 100);
    } else {
      // load file from API
      sequenceApi.getStructurePdb({ structureId: firstInRow.id })
        .then((data) => {
          setTimeout(() => {
            createNGLStructure(data, false);
          });
        })
        .catch((error) => {
          console.error('Get sequence group info data error:', error);
        });
    }
  }
};

const renderItem = ({ id, source_title, link, external_id, ngl_link }: StructureItem) => {
  const { pathname } = useLocation();

  const href = external_id
    ? `${pathname}/structure?structure_id=${id}&url=${ngl_link}`
    : `${pathname}/structure?structure_id=${id}`;

  return (
    <List.Item key={id}>
      <Typography.Text ellipsis>{source_title}: {link}</Typography.Text>

      <Link
      className={stylesheet.viewButton}
      to={href}
    >
      <Button
        type="primary"
        icon={<RadarChartOutlined />}
      >
        <span>View Structure Full Screen</span>
      </Button>
    </Link>

    </List.Item>
  );
};

const createNGLStructure = (file: string, isLink: boolean) => {
  async function init() {
    const elem = document.getElementById('ngl-container');
    if (elem) {
      window.molstar = await createPluginUI(elem as HTMLDivElement);

      const data = isLink
        ? await window.molstar.builders.data.download(
          { url: file }, /* replace with your URL */
          { state: { isGhost: true } }
        )
        : await window.molstar.builders.data.rawData({ data: file, label: 'test' });

      const trajectory = await window.molstar.builders.structure.parseTrajectory(data, 'pdb');
      await window.molstar.builders.structure.hierarchy.applyPreset(trajectory, 'default');
    } else {
      console.log('Could not get ngl-container element');
    }
  }
  void init();
};

export function StructureTab({ sequenceId, structures, loading }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const getItems = useMemo(() => {
    return getSourceItems(structures, sequenceId);
  }, [structures]);

  useEffect(() => {
    if (structures) {
      buildStructure(structures, sequenceId);
    }

    return () => {
      window.molstar?.dispose();
      window.molstar = undefined;
    };
  }, [structures]);

  if (!loading && !structures.length) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={(<span>No data</span>)}
      />
    );
  }

  return (
    <div className={stylesheet.structureTab}>

      <List
        loading={loading}
        className={stylesheet.list}
        style={{ width: getItems.length ? '50%' : '100%' }}
        dataSource={getItems}
        renderItem={renderItem}
      />
      {!loading && <div id="ngl-container" ref={ref} className={stylesheet.structureContainer} />}
    </div>
  );
}
