import { useEffect, useState } from 'react';

import { Empty, Spin } from 'antd';
import _ from 'lodash';
// API
import { sequenceApi } from 'api';
// Types
import { Sequence, Tag, TagLabel } from 'types';
// Partials
import { TagView } from './TagView';
import { TagAttachForm } from './TagAttachForm';
// Styles
import stylesheet from './TagTab.module.scss';

type Props = {
  projectId: string;
  sequenceId?: string;
  sequence: Sequence;
  tags: Tag[];
  loading: boolean;
};

type CombineTag = Tag & TagLabel;

export function TagTab({ projectId, sequenceId, sequence, tags, loading }: Props) {
  const [tagsList, setTagsList] = useState<Tag[]>([]);
  const [projectTags, setProjectTags] = useState<CombineTag[]>([]);
  const [sequecneTags, setSequenceTags] = useState(sequence?.tags || []);

  const filterTags = (list: Tag[], tagsList: any) => {
    const projectTags = _.map(tagsList, (item: any) => {
      return {
        ...item,
        ...list.find(it => {
          return it.uuid === item.tag_id;
        }),
      };
    });

    setProjectTags(projectTags);
  };

  const filterTagsList = (list: Tag[], tags: any) => {
    const projectTagIds = tags?.length ? tags.map((it: TagLabel) => it.tag_id) : [];
    const availableList = list.filter(item => !projectTagIds.includes(item.uuid));

    setTagsList(availableList);
  };

  const renderTags = (list: CombineTag[]) => {
    return list.map(item => {
      return (
        <TagView
          key={item.uuid}
          item={item}
          projectId={projectId}
          sequenceId={sequenceId}
          removeTagFromList={removeTagFromList}
        />
      );
    });
  };

  const fetchProjectTags = (tags: any) => {
    projectId && sequenceApi.getProjectTags({ projectId })
      .then(data => {
        filterTagsList(data, tags);
        filterTags(data, tags);
      })
      .catch(() => {});
  };

  const removeTagFromList = (id: string) => {
    const tags = sequecneTags.filter(item => item.tag_id !== id);
    setSequenceTags(tags);
    fetchProjectTags(tags);
  };

  const addTagToList = (tag: any) => {
    const newList = [...projectTags, tag];

    setProjectTags(newList);
    setSequenceTags(newList);
    filterTagsList(tagsList, newList);
  };

  useEffect(() => {
    setProjectTags([]);
    setSequenceTags(sequence?.tags);
  }, [sequence]);

  useEffect(() => {
    filterTagsList(tags, sequence?.tags);
    filterTags(tags, sequence?.tags);
  }, [tags]);

  if (loading) {
    return <div className={stylesheet.loading}><Spin spinning={loading} /></div>;
  }

  if (!sequence) {
    return <Empty className={stylesheet.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <div className={stylesheet.tagsCont}>
      <div className={stylesheet.tagsList}>
        {!!projectTags.length &&
          <div className={stylesheet.list}>
            {renderTags(projectTags)}
          </div>
        }
        <TagAttachForm
          tags={tagsList}
          projectId={projectId}
          sequenceId={sequenceId}
          addTagToList={addTagToList}
          fetchProjectTags={() => fetchProjectTags(sequecneTags)}
        />
      </div>
    </div>
  );
}
