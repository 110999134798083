export const GeneticCodes =
{
  11: {
    name: 'Bacterial, Archaeal and Plant Plastid',
    order: 0
  },
  1: {
    name: 'Standard',
    code: 'SGC0',
    order: 1,
  },
  2: {
    name: 'Vertebrate Mitochondrial',
    code: 'SGC1',
    order: 2
  },
  3: {
    name: 'Yeast Mitochondrial',
    code: 'SGC2',
    order: 3
  },
  4: {
    name: 'Mold Mitochondrial; Protozoan Mitochondrial; Coelenterate Mitochondrial; Mycoplasma; Spiroplasma',
    code: 'SGC3',
    order: 4
  },
  5: {
    name: 'Invertebrate Mitochondrial',
    code: 'SGC4',
    order: 5
  }
};
