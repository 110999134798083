import { useState } from 'react';
import { Structure } from 'types';
import { Input, Flex, Button, Typography, Modal, List, InputNumber } from 'antd';
import { createProteinMPNNJob } from 'api/structure';
import { getStructures } from 'helpers/getStructures';
import { RenderedStructureItem } from './RenderedStructureItem';
import stylesheet from './OptimisationTab.module.scss';
import './OptimisationTab.scss';

type Props = {
  sequenceId: string;
  structures: Structure[];
  loading: boolean;
  projectId: string;
};

export function OptimisationTab({ sequenceId, structures, loading, projectId }: Props) {
  const [structureId, setStructureId] = useState('');
  const [sequenceGroupName, setSequenceGroupName] = useState('');
  const [numSequences, setNumSequences] = useState(20);
  const [showProteinMPNN, setShowProteinMPNN] = useState(false);
  const [fixedPositions, setFixedPositions] = useState('');
  const [samplingTemp, setSamplingTemp] = useState(0.1);
  const [nextStepClicked, setNextStepClicked] = useState(false);
  const [fixedPositionsValid, setFixedPositionsValid] = useState(true);

  const structureItems = getStructures(structures, sequenceId);

  function isValidCommaSeparatedIntegers(input: string): boolean {
    // Regular expression to match an empty string or a comma-separated list of positive integers
    const regex = /^(\d+)(,\d+)*(,\d*)?$/;

    // Check if the input matches the regular expression
    return input === '' || regex.test(input);
  }

  const renderStep = () => {
    if (!nextStepClicked) {
      return (
        <div>
          <Typography>Select a structure to optimise</Typography>
          <List>
            {structureItems.map((structureItem) => {
              return <RenderedStructureItem key={structureItem.id} item={structureItem} structureId={structureId} setStructureId={setStructureId} />;
            })}
          </List>

          <Button disabled={structureId === '' || loading} onClick={() => { setNextStepClicked(true); }} >Next </Button>
        </div>
      );
    } else {
      return (
        <Flex vertical={true} style={{ minWidth: '900px' }}>
          <Flex style={{ padding: 3 }} vertical={false}>
            <Typography style={{ width: '300px', paddingRight: '2' }}>Sequence Group Name (Required)</Typography>
            <Input style={{ width: '300px' }} onChange={(evt) => setSequenceGroupName(evt.target.value)} value={sequenceGroupName} />
          </Flex>

          <Flex style={{ padding: 3 }} vertical={false}>
            <Typography style={{ width: '300px', paddingRight: '2' }}>Number of Sequences</Typography>
            <InputNumber type="number" min={1} style={{ width: '300px' }} onChange={(evt) => {
              if (evt) {
                setNumSequences(evt);
              }
            }} value={numSequences} />
          </Flex>

          <Flex style={{ padding: 3 }} vertical={false}>
            <Typography style={{ width: '300px', paddingRight: '2' }}>Fixed Positions (comma-separated list of amino acid position numbers, only numbers separated by commas or leave empty)</Typography>
            <Input style={{ width: '300px', paddingRight: '2' }} onChange={(evt) => {
              const isValid = isValidCommaSeparatedIntegers(evt.target.value);
              setFixedPositionsValid(isValid);
              if (isValid) {
                setFixedPositions(evt.target.value);
              }
            }} value={fixedPositions} status={fixedPositionsValid ? undefined : 'warning'} />
            <Typography style={{ width: '300px', color: 'red' }}>
              {!fixedPositionsValid && 'Invalid input. Please enter a comma-separated list of positive integers or leave empty.'}
            </Typography>
          </Flex>

          <Flex style={{ padding: 3 }} vertical={false}>
            <Typography style={{ width: '300px', paddingRight: '2' }}>Sampling Temp</Typography>
            <InputNumber style={{ width: '300px' }} step={0.01} type="number" min={0} max={1} onChange={(evt) => {
              if (evt) {
                setSamplingTemp(evt);
              }
            }} value={samplingTemp} />
          </Flex>
          <Button disabled={sequenceGroupName.length === 0} style={{ width: '350px', marginTop: '10px' }} type='primary' onClick={() => {
            void createProteinMPNNJob({ structureId, sequenceGroupName, numSequences, projectId, fixedPositions: fixedPositions.split(',').map((pos) => parseInt(pos, 10)).filter((pos) => !isNaN(pos)), samplingTemp });
            setNextStepClicked(false);
            setShowProteinMPNN(false);
          }} >
            Create ProteinMPNN Job
          </Button>
        </Flex>
      );
    }
  };
  return (
    <div>
      <Button onClick={() => { setShowProteinMPNN(true); }}>Optimise via Inverse Folding (ProteinMPNN)</Button>
      <Modal footer={<></>} width={nextStepClicked ? '1000px' : '100%'} centered={false} title={'ProteinMPNN Options'} style={{ marginBottom: 10, width: 1400 }} open={showProteinMPNN} onCancel={() => { setNextStepClicked(false); setShowProteinMPNN(false); setFixedPositionsValid(true); setFixedPositions(''); setSamplingTemp(0.1); setSequenceGroupName(''); }}>
        <div className={stylesheet.optimisationTab}>
          {
            renderStep()
          }
        </div>
      </Modal>
    </div>
  );
}
